/* WriterLoginScreen.css */
.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #FFF9F2;
  }
  
  .contentContainer {
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: column; 
    align-items: center;
    padding-left: 0;
    padding-right: 0;
  }
  
  .boxContainer {
    margin-bottom: 20px; /* adjust this value as needed */
  }