.socialMediaFormModal {

    font-family: Arial, sans-serif;
  }
  
  .socialMediaFormModal label {
    color: black;
    font-size: 16px;
  }
  
  .socialMediaFormModal input[type="text"] {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 20px; /* Consistent spacing between elements */
    border: 1px solid gray;
    border-radius: 2px;
  }
  
  .socialMediaFormModal button {
    background-color: #E9DCD1;
    color: black;
    border: none;
    padding: 10px 20px;
    border-radius: 2px;
    cursor: pointer;
    width: 100%;
    font-weight: normal; /* Adjusted for emphasis */
  }
  
  /* Ensure all elements are left-aligned for consistency */
  .formGroup {
    width: 100%; /* Ensure formGroup takes full width */
  }
  