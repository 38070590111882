.writers-footer {
    display: flex;
    justify-content: center; /* Distribute footer items evenly */
    align-items: center; /* Center items vertically */
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #E9DCD1; /* Adjusted to match your theme */
    padding: 10px 0;
    box-shadow: 0 -2px 4px rgba(0,0,0,0.1);
  }
  
  .footer-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .footer-item .label, .footer-item .arrow {
    margin: 0;
    padding: 0;
  }


  .icons-container {
    display: flex;
    justify-content: space-around; /* Evenly distribute footer items within the container */
    align-items: center;
    width: 100%;
    max-width: 575px; /* Limit container width to 575px */
  }
  
  .footer-icon {
    width: 56px;
    height: 56px;
    margin-bottom: 5px; /* Space between the icon and label */
  }


  

  
  .writers-footer a {
    color: inherit;
    text-decoration: none;
  }

  .writers-footer span {
    font-family: 'Arial', sans-serif; /* Example of sans-serif font */
  }

  /* Style for arrows */

  
  /* Hidden arrows have no content but take up space */
  .arrow, .label {
    flex: 1;
    text-align: center;
    font-size: 12px;
    
  }
  
  /* These styles will make the arrows invisible when not focused but still take up space */
  .hidden {
    visibility: hidden;
  }
  
  /* Adjust the CSS for the arrows to ensure they take up equal space on both sides */
  .arrow.left {
    margin-right: auto; /* Pushes the arrow to the left and label to the right */
  }
  
  .arrow.right {
    margin-left: auto; /* Pushes the arrow to the right and label to the left */
  }
  
  /* Visible arrows */
  .visible {
    visibility: visible;
  }
  
  /* .writers-footer a:hover {
    text-decoration: underline;
  }
   */

   
   .label-container {
    display: flex;
    justify-content: center; /* Centers the label and arrows horizontally */
    align-items: center; /* Centers the label and arrows vertically */
    flex-grow: 1; /* Allows the container to expand to fill the space */
  }