.app-store {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .app-store p {
    width: 275px; /* Set the width of the paragraph */
    text-align: center; /* Optional: Center the text if desired */

  }
  
  .store-images {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 300px;  /* adjust this to fit the size of your images */
    margin-top: 10px;
  }
  
  .store-images img {
    width: 100%;
    max-width: 140px;  /* adjust this to fit the size of your images */
    margin-bottom: 40px;
  }