/* HeaderPublish.css */

.header-publish-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: #E9DCD1;
    /* Ensure it spans the full width and stays at the top */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
  }
  
  .publish-step {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  /* Use ::after pseudo-element to create arrows */

  
  .active-icon {
    width: 60px;
    height: 60px;
    filter: opacity(1); /* Full color */
  }
  
  .inactive-icon {
    width: 60px;
    height: 60px;
    filter: opacity(0.2); /* Gray scale */
  }

  .active-arrow {
    opacity: 1; /* Full visibility */
    margin: 0 20px;
  }
  
  .inactive-arrow {
    opacity: 0.2; /* Reduced visibility for inactive state */
    margin: 0 20px;
  }
  