.writing-area {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding-top: 100px;
    width: 100%;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
  }



  .title-description-container {
    border: 1px solid #d3d3d3;
    width: 100%;
    max-width: 600px;
    /* margin-top: 20px; */
    

    border-radius: 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    border-bottom: 1px solid #d3d3d3; 
    box-sizing: border-box;
    
  }
  
  .title-description-header {
    font-weight: bold;
    margin-bottom: 10px;
    letter-spacing: 0.5px;
    padding: 7px 0px;
    width: 100%;
    font-size: 16px;
    color: #2F2B27;
    font-family: sans-serif;
    align-items: center;
    /* margin-top: 20px; */
    background-color: #E9DCD1;
    text-align: center;
    /* background-color: #E9DCD1; */
  }
  
  
  /* WritersPublishWriting.css */
  
  .piece-editor-container {
    width: 100%;
    max-width: 600px;
    margin-bottom: 20px;
    background-color: white;
    box-sizing: border-box;



    border: 1px solid #ccc;
    border-radius: 1px;
    font-size: 16px;
  }

  .title-input, .description-input {
  width: 90%; /* Adjust width within the container */
  margin: 5px 0; 
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #d3d3d3; 


  }

  .title-input {
    width: 90%; /* Adjust width within the container */
    margin: 5px 0; 
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px solid #d3d3d3; 
  
  
    }

    .button-separatorest {
      height: 1px;
      background-color: #d3d3d3;
      margin: 10px 0;
      width: 100%;
      max-width: 600px;
    }

  .buttons-container {
    display: flex;
    flex-direction: column; /* Default to vertical layout */
    align-items: center;
    gap: 10px; /* Space between buttons */
    width: 100%; /* Fill container width */
    max-width: 600px;
  }
  .infosssButton {
    background-color: #E9DCD1;
    color: black;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%; /* Fill container width */
    max-width: 600px;
    margin: 10px 00px;
  }
  
  @media (min-width: 451px) {
    .buttons-container {
      flex-direction: row; /* Horizontal layout for wider screens */
    }
    .first-button {
      order: 2; /* The discard button comes second */
    }
    .second-button {
      order: 1; /* The use text button comes first */
    }
  }

  .description-input {

    height: 50px; /* Adjust height as needed */
  
  
    }

    .infosButton {
      background-color: #E9DCD1;
      color: black;
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }
  


  