
  
  .addLinkModalContainer {
    background: #FFF9F2;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 450px;
  }
  
  .addLinkModalError {
    color: red;
    margin-bottom: 10px;
  }
  
  .addLinkModalInput, .addLinkModalTextarea {
    width: 95%;


    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .addLinkModalTextarea {
    height: 60px;
  }
  .addslinkswriting {
    font-size: 16px;
    color: #000;

    margin-bottom: 10px;
  }
  
  .addLinkModalButton {
    background-color: #E9DCD1;
    height: 35px;
    border: 0.1px solid rgb(76, 76, 76);
    border-radius: 2px;
    cursor: pointer;
    margin-top: 10px;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }

  .discardLinkModalButton {
    background-color: #E9DCD1;
    height: 35px;
    border: 0.1px solid rgb(76, 76, 76);
    border-radius: 2px;
    cursor: pointer;
    margin-top: 10px;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .separatorestest{    
    border-bottom: 1px solid #d3d3d3;
    margin-top: 10px;
    width: 100%;
}
  