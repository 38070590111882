.bookContainer {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .bookItemsContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .bookItem {
    width: 22%;
    text-align: center; /* Centering the image */
    cursor: pointer;
  }
  
  .bookImage {
    width: 60%; /* Adjust width as needed */
    height: auto; /* Maintain aspect ratio */
    /* Adjust aspect ratio to match your requirements */
  }
  