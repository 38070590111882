.writers-home {
    display: flex;
    flex-direction: column;
    align-items: center;  /* Keeps content centered horizontally */
    padding-top: 20px;    /* Adjusted to reduce the top space */
    width: 100%;
    min-height: 85vh;     /* Ensures it takes at least the height of the viewport */
    overflow-y: auto;  
}
  
  .writers-home-boxes {
    margin: 0 20px; 
    border: 1px solid #d3d3d3; 
    width: 100%; /* Adjusted to the desired max width */
    max-width: 450px;
    box-sizing: border-box; 

    padding: 20px;

  }
  
  .titleContainerey {
    align-items: left; 
    width:100%;
    margin-top: 0px;
    margin-bottom: 20px;
  }
  
  .writers-home-title {
    font-size: 24px;
    margin-top: 0px;
    color: #2F2B27;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    margin-bottom: 0px;
    font-family: sans-serif;
    /* letter-spacing: 0.5px; */
    letter-spacing: 0.05em; 

    
  }

  .infosButton-writers-home {
    background-color: #E9DCD1;
    height: 35px;
    border: 0.1px solid rgb(76, 76, 76);
    border-radius: 2px;
    
    cursor: pointer; /* Indicate it's clickable */
    margin-bottom: 15px;
    margin-top: 25px;
    
  
    width: 100%; /* Fill the container width */
    justify-content: center;
    display: flex;
    align-items: center;
    transition: opacity 0.1s;
    box-sizing: border-box;
  }

  .writers-home-sub-title {
    font-size: 16px;
    font-weight: bold;
    color: black;
    width: 100%;
    margin-bottom: 6px;
    margin-top: 17px;
    letter-spacing: 0.05em; 
}

.writers-home-text {
    font-size: 16px;
    font-weight: normal;
    color: black;
    width: 100%;
    margin-bottom: 0px;
    letter-spacing: 0.05em; 
    line-height: 1.25;

}

.dashboardContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /* Three equal columns */
  gap: 20px; /* Space between columns */
  max-width: 500px;
}



.homeview-feed-name {
  font-weight: bold;

  font-size: 20px;
  
  /* New properties to left-align the element */
  align-self: flex-start; /* Overrides the parent’s align-items */
  width: 100%;   
  
}

.feedNameContainer {
  width: 100%;
  max-width: 450px;        /* Ensures the container doesn't exceed 450px */
  text-align: left;        /* Left-aligns the text within the container */
  padding-left: 20px;      /* Optional: Adds left padding for better aesthetics */
  margin-top: 20px;
  margin-bottom: 10px;

}

.dashboardContainerTwo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px; 
  max-width: 500px;
}

.subtle-line {
  border-bottom: 1px solid #d3d3d3;
  margin-top: 20px;
  max-width: 450px;

  width: 100%;
}
.subtle-line-a-bit-more {
  border-bottom: 1px solid #d3d3d3;
  margin-top: 20px;
  max-width: 450px;
  margin-bottom: 15px;

  width: 100%;
}


.metricBlock {

  padding: 10px 20px;
  border-radius: 8px; /* Rounded corners */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; /* Stack items vertically */
}

.imageBlock {
  display: flex;
  align-items: center;
  justify-content: center;
}

.metricBoldDash {
  font-size: 2.2em; /* Large font for metric number */
  margin: 0px; /* Remove default margin */

  text-align: center; 
}

.metricDescription {
  margin:0px;
  font-size: 0.9em; /* Smaller font for description */
  color: #666; /* Less prominent color for the description */
  text-align: center; 
}

.pancakeroos {
  opacity: 0.8;
}

.two-axis-chart-container {
  padding: 0;
  margin: 0;
  max-width: 500px;
  align-items: center; 
  display: flex;
  flex-direction: column;


}
.writers-home__delve-button {
  background-color: #E9DCD1;
  height: 35px;
  border: 0.1px solid rgb(76, 76, 76);
  border-radius: 2px;
  cursor: pointer;
  margin-top: 15px;
  width: 95%;
  justify-content: center;
  display: flex;
  align-items: center;
  transition: opacity 0.1s;
  box-sizing: border-box;
  font-size: 14px;
  letter-spacing: 0.05em;
  max-width: 435px;
}

.writers-home__delve-button:hover {
  opacity: 0.8;
}


