.in-Bolds-Button-details {
    background-color: #E9DCD1;
    height: 35px;
    border: 0.1px solid rgb(76, 76, 76);
    border-radius: 2px;
    cursor: pointer; /* Indicate it's clickable */
    margin-bottom: 15px;
    margin-top: 10px;
  
    width: 100%; /* Fill the container width */
    justify-content: center;
    display: flex;
    align-items: center;
    transition: opacity 0.1s;
    box-sizing: border-box;
    font-weight: bold;
  }