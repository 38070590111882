/* LoginBox.css */
.loginBox {
  border: 1px solid #d3d3d3;
  border-radius: 2px;
  padding: 20px;
  width: 350px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inputContainer {
  width: 100%;
  padding-top: 20px;
}

.input {
  height: 40px;
  width: 100%;
  color: black;
  border: 1px solid gray;
  background-color: white;
  margin-bottom: 10px;
}

.submitButton {
  background-color: #E9DCD1;
  height: 40px;
  border: 0.1px solid black;
  border-radius: 2px;
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.2s ease;
  color:black;
}

.submitButton:active {
  opacity: 0.7; /* Reduced opacity when the button is clicked */
}

.submitButtonText {
  font-weight: bold;
  color: black;
}

.separator {
  border-bottom: 1px solid #d3d3d3;
  margin: 10px 0;
  width: 100%;
}
.googleSignIn {
  cursor: pointer;
}

.forgotPassword {
  color: black;
  text-align: center;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  margin-top: 10px;
}
