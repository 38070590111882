/* AppFormLastWords.css */
.appFormLastWordsContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    background-color: #FFF9F2;
    border-radius: 5px;
    border: 1px solid #d3d3d3;
    max-width: 550px;
    margin: auto;
    font-family: Arial, sans-serif;
  }
  
  .titleContainer-lastwords {
    width: 100%;
    text-align: center;

  }

  .bold-words {
    font-weight: bold;
    font-size: 18px;
    color: black;
    text-align: center;
  }
  
  .instructionText {
    line-height: 1.6;
    /* margin-bottom: 20px; */
    text-align: left; /* Left align text in paragraphs */
    color: black;
  }
  
  .lastWordsTextArea {
    width: 95%;
    height: 100px; /* Adjust the height as needed */
    /* padding: 10px; */
    margin-bottom: 10px;
    border: 1px solid #d3d3d3;
    border-radius: 4px;
    /* font-family: Arial, sans-serif; */
    resize: none;

  }
  
  .applyButton, .discardButton, .previewButton {
    background-color: #E9DCD1;
    color: black;
    border: none;
    padding: 10px 20px;
    border-radius: 2px;
    cursor: pointer;
    width: 100%;
    margin-top: 10px;
  }

  .previewButton {
    font-weight: bold;
    
  }
  
  .separator {
    border-bottom: 1px solid #d3d3d3;
    margin-top: 20px;
    width: 100%;
  }
  