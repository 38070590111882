.reusableBoxContainer {
    /* margin: 10px; */
    margin-top: 10px;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
  }
  
  .contentViewInBox {
    /* padding: 20px; */
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    border: 1px solid #d3d3d3;
    /* border-radius: 5px; */
    /* width: 100%; */
  }
  
  .textInBox {
    font-size: 16px;
    text-align: left;
    margin-bottom: 10px;
    font-family: sans-serif;
    
  }

  .leftAlignedTitle {
    text-align: left !important;
  }
  
  .boldInBox {
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    margin-bottom: 10px;
    font-family: sans-serif;
  }
  
  .buttoninbox {
    background-color: #E9DCD1;
    height: 40px;
    border: 0.1px solid black;
    border-radius: 2px;
    width: 100%;
    margin-top: 10px;
    justify-content: center;
    cursor: pointer;
  }
  
  .boldButton {
    font-weight: bold;
  }
  
  /* ReusableBoxContainer.css */

.textInput, .largeInput {
  box-sizing: border-box; 
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #E0D1C1;
  border-radius: 7px;
  font-size: 14px;
  background-color: #F8F6F4;
  color: black;
}

.reusableBoxContainer .largeInput {
  height: 100px; /* Larger height for multiline input */
  text-align: left; /* Align text for large input */
}

/* Include other styles for .reusableBoxContainer, .boldInBox, .textInBox, etc., as before */
