.modal {
    background: #FFF9F2;
  
    border-radius: 10px;
  
    max-width: 450px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    margin: auto; /* Center the modal in the viewport or its parent */
  }
  
  .image-preview {
    padding-top: 0px;
    margin-top: 0px;
  
    width: 160px;
    height: auto;
  
  }
  
  .contentBox {
    border: 1px solid #d3d3d3; /* Example border, change as needed */
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px; /* Space between this box and the next content */
    width: 100%;
    box-sizing: border-box;
  }
  
  .info-text {
    margin-top: 20px;
    font-size: 14px;
    color: #333;
  }
  .modalTitle {
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    margin-bottom: 10px;
    font-family: sans-serif;
  }
  
    .textButtonContainer {
      width: 100%; /* Ensures the container spans the full width */
      display: flex;
      justify-content: space-between ; /* Space out buttons across the container */
      padding-bottom: 0px;
    }
    .titleContainer {
      width: 100%;
      text-align: center;
      margin-top: 0px;
      padding-top: 0px;
      padding-bottom: 0px;
      margin-bottom: 10px;
    }
  
  .textButton {
    background: none;
    border: none;
    color: black ; /* or any color you prefer */
    padding-top: 20px;
    width: calc(100% - 40px);
    margin-right: 0;
    margin-left: 0;
    padding-bottom: 10px;
    cursor: pointer;
    display: block; /* Ensure it spans the full line */
    box-sizing: border-box;
  }
  
  .infosButton {
    background-color: #E9DCD1;
    height: 35px;
    border: 0.1px solid rgb(76, 76, 76);
    border-radius: 2px;
    cursor: pointer; /* Indicate it's clickable */
    margin-bottom: 5px 0;
    margin-top: 10px;
  
    width: 100%; /* Fill the container width */
    justify-content: center;
    display: flex;
    align-items: center;
    transition: opacity 0.1s;
    box-sizing: border-box;
  }
  
  /* Add other styles as needed */
  