.feedContainer {
    width: 100%;
    margin-bottom:-10px;
  }
  
  .emptyFeedMessage {
    font-style: italic;
    color: black;
  }
  
  .feedName {
    font-weight: bold;
    margin-bottom: 12px;
    font-size: 16px;
    color: black;
  }
  
  .feedItemsContainer {
    display: flex;
    justify-content: space-between;
  }
  
  .feedItem {
    width: 22%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  
  .itemImage {
    aspect-ratio: 1 / 1.5;
    width: 100%;
  }
  
  .itemTitle {
    text-align: center;
    margin-top: 8px;
    font-size: 14px;
    width: 100%;
  }

  .removeBookButton {
    background-color: white; 
    position: absolute;
    top: 5px;
    right: 3px;
    margin:0px;
    padding: 0px;
    border: none;
    cursor: pointer;
  }
  
  .removeBookButton img {
    width: 20px; /* Or any size you prefer */
    height: 20px;
  }
  