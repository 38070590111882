/* AppFormNamePublished.css */
.applyFormOneContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the left */
  padding: 20px;
  background-color: #FFF9F2;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
  max-width: 350px;
  margin: auto;
  font-family: Arial, sans-serif; /* Consistent font family */
}

label {
  color: black;
  margin-bottom: 10px;
}

input[type="text"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid gray;
  border-radius: 2px;
  box-sizing: border-box;
}
.radioWrapper {
  display: flex;
  justify-content: center;
  width: 100%; /* Full width of the container */
  margin-top: 10px;
  /* margin-bottom: 30px; */

}

.bold-prelim {
  font-weight: bold;
  font-size: 18px;
  color: black;
/* Align the title to the left */
}

.titleContainer {
  width: 100%; /* Full width of the container */
  text-align: center; /* Center align the title */
  margin-bottom: 20px; /* Space below the title */
}

.radioOptions {
  display: flex;
  text-align: center;
  margin-right:20px;
}

.radioOptions input[type="radio"] {
  margin-right: 5px;
  accent-color: black; /* Color when selected */
}

.radioOptions label {
  margin-right: 15px; /* Adjust spacing between radio option and label */
  
}

/* Ensure the radio button and label pairs are inline */
.radioOptions input[type="radio"], .radioOptions label {
  display: inline-block;
  
}




.applyButton, .discardButton {
  background-color: #E9DCD1;
  color: black;
  border: none;
  padding: 10px 20px;
  border-radius: 2px;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
}

.applyButtonText {
  font-weight: bold; /* Make the text bold */
  color:black;
}


.separator {
  border-bottom: 1px solid #d3d3d3;
  margin: 20px 0;
  width: 100%;
}

.dashed-separator {
  border-bottom: 1px dashed #d3d3d3; /* Change from solid to dashed */
  margin: 20px 0;
  width: 100%;
}