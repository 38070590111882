/* ReadersSection.css */

.readers-section {
    display: flex;
    align-items: center; /* To vertically center the text and the GIF */
    justify-content: left; /* To horizontally center the content */
    width: 100%; /* Fill the container width */
    position: relative; /* To position the text next to the GIF */
    margin-top: -25px;
  }
  .writers-section {
    display: flex;
    align-items: center; /* To vertically center the text and the GIF */
    justify-content: right; /* To horizontally center the content */
    width: 100%; /* Fill the container width */
    position: relative; /* To position the text next to the GIF */
    margin-top: -30px;
    margin-bottom: 0px;
  }
  
  .readers-arrow {
    width: 70px; /* Set the width of your GIF */
    transform: rotate(160deg); /* Rotate the GIF if needed */
    margin-right: -15px; /* Add some space between the GIF and the text */
    margin-left: -20px;
  }

  .written-stuff {
    text-align: right; /* Align text to the right */
    margin-bottom: 10px;;
    margin-right: -15px;
}

  .writers-arrow {
    width: 70px; /* Set the width of your GIF */
    transform: rotate(-20deg); /* Rotate the GIF if needed */
    margin-right: 0px; /* Add some space between the GIF and the text */
    margin-left: -10px;
    margin-right: -20px;
  }
  
  .readers-text {
    font-size: 14px; /* Set the size of your text */
    font-weight: bold; /* Make the text bold */
    font-family: sans-serif;
    letter-spacing: 1px;
  }

  .writers-text {
    font-size: 14px; /* Set the size of your text */
    font-weight: bold; /* Make the text bold */
    font-family: sans-serif;
    /* Add other styles as needed */
    display: block;
    margin-bottom: 3px;
    letter-spacing: 1px;
  }

  .writers-subtext {
    font-size: 11px; /* Set the size of your text */
    font-weight: bold; /* Make the text bold */
    font-family: sans-serif;
    /* Add other styles as needed */
    display: block;
    letter-spacing: 0.5px;
  }
  
  /* If you want the text to be a "sticker" like in Instagram, you can add more styling: */
  .readers-text {

    padding: 0px 0px; /* Padding inside the text sticker */
    margin-left: -10px;


  }
  