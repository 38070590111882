.advertisement-piece-box {
    border: 1px solid #d3d3d3;
    padding: 10px 15px;
    margin-top: 10px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }

  
  .advertisement-label {
    font-weight: bold;
  }
  
  .piece-title {
    font-weight: normal;
  }