.preview-piece-page {
    background-color: #FFF9F2;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  
  .preview-contents-container {
    width: 100%;
    max-width: 575px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .preview-italic-text {
    font-style: italic;
  }
  
  .preview-promoted-books-text {
    text-align: right;
    margin-bottom: 10px;
    font-size: 15px;

  }

  .buttonPreviewPiece {
    background-color: #E9DCD1;
    height: 35px;
    border: 0.1px solid rgb(76, 76, 76);
    border-radius: 2px;
    cursor: pointer; /* Indicate it's clickable */
    width: 100%; /* Fill the container width */
    justify-content: center;
    display: flex;
    align-items: center;
    transition: opacity 0.1s;
    margin-top: 20px;
  }


  
  .preview-genre-style-container {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
  }
  
  .preview-divider {
    border-bottom: 1px solid #ccc;
    width: 100%;
    opacity: 0.5;
    margin: 10px 0;
  }
  
  .preview-piece-image-container img {
    width: 100%;
    margin: 10px auto;
    display: block;
  }
  
  .preview-piece-content {
    text-align: justify;
    margin-top: 10px;
    font-family: sans-serif; /* Changes the font to sans-serif */
    letter-spacing: 0.05em;
  }
  
  /* Add these styles to your WritersPublishPreview.css */
.previews-author-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px; /* Space before the piece content */
  }

  .preview-author-only {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px; /* Space before the piece content */
  }
  
  .preview-author-photo {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 0.5rem;
  }
  
  .preview-author-name {
    font-weight: bold;
    font-size: 1.0em;
    font-family: sans-serif;
    letter-spacing: 0.08em;
  }
  
  .preview-length-indicator {
    font-family: sans-serif;
    letter-spacing: 0.12em;
    font-size: 0.9em;
    margin-right: 5px;
    padding-top: 6px;
  }


   .preview-pieces-title {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    margin-top: 5px;
  }
  
  .preview-authors-name {
    margin-left: 10px;
    font-weight: normal;
  }
  