.header-main {
  font-family: Prestige-Normal;
  font-size: 24px;
  background-color: #E9DCD1;
  color: #30221C;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  margin: 0;
  letter-spacing: 4px;
  position: relative;
}

.header-content-second {
  max-width: 690px;
  margin: 0 auto;
  padding: 0 33px;
  box-sizing: border-box;

  /* Center the content horizontally and position so we can absolutely
     position the pancake container. */
  display: flex;
  justify-content: space-between;
  align-items: center;
     
  position: relative;
}

.header-content-second h1 {
  /* Ensure it’s treated like an inline block so text-align center works properly. */
  display: inline-block;
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-weight: bold;
  font-family: Prestige-Normal;
  cursor: pointer; /* if you want the pointer on hover */
}

.pancake-buttons {
  /* Absolutely position the pancake so it doesn’t affect the heading’s centering. */
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%); 
}

.pancake-buttons button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.header-content-left h1 {
  cursor: default; 
  display: flex;
  align-items: center;
}

.pancake-buttons img {
  width: 35px;
  height: 35px;
}

.filter-indicator {
  position: absolute;
  top: -10px;
  right: -10px;
  color: black;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  z-index: 1;
}

.pancake-dropdown {
  position: absolute;
  top: 34px; /* just below the pancake button */
  right: 3px;
  background-color: #FFF9F2; /* match header bg */
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 8px 0;
  width: 260px; /* adjust as needed */
}

/* Unordered list styling inside dropdown */
.pancake-dropdown ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* List items in dropdown */
.pancake-dropdown li {
  padding: 8px 16px;
  cursor: pointer; /* so the text isn’t centered */
  font-family: sans-serif; /* or your desired font */
  font-size: small;
}

.pancake-dropdown li:hover {
  background-color: #D3C4B6; /* a slightly darker variant of #E9DCD1 or whichever color you prefer */
}