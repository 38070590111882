.profileFeed-contentContainer {
    width: 100%;
    max-width: 575px;
    margin: 0 auto;
    padding: 20px;
    padding-top: 5px; /* Space between the top of the container and the first item */
    box-sizing: border-box;
  }
  
  .profileFeed-feedItem {
    margin-bottom: 20px; /* Space between items */
  }
  
  .profileFeed-pieceImage {
    width: 100%;
    max-width: 575px; /* Adjust as needed */
    margin-bottom: 10px;
    cursor: pointer;
  }
  
  .profileFeed-authorPhoto {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 0.5rem;
    object-fit: cover; 
  }
  .profileFeed-deleteIcon {
    width: 40px; /* Set the width of the icon */
    height: 40px; /* Set the height of the icon */
    cursor: pointer; /* Change cursor to pointer to indicate it's clickable */
    margin-top:0px;
    margin-right: 0px; /* Add some space to the left */
    vertical-align: middle; /* Align vertically with adjacent text or elements */
    margin-bottom: -10px;
  }

  .iconista {
    width: 20px; /* Set the width of the icon */
    height: 20px; /* Set the height of the icon */

    margin-top:0px;
    margin-right: 0px; /* Add some space to the left */
    vertical-align: middle; /* Align vertically with adjacent text or elements */

  }
  
  .profileFeed-pieceInfo {
    font-weight: normal;
    font-family: sans-serif;
    letter-spacing: 0.05em;
    text-align: justify;
  }
  .clickable {
    cursor: pointer;
  }
  
  .profileFeed-authorAndLengthInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px; /* Space before the piece image */
  }
  
  .profileFeed-authorInfo {
    display: flex;
    align-items: center; /* Center items vertically */
    gap: 10px;
  }
  
  .profileFeed-authorName {
    font-weight: bold; /* Make text bold */
    font-size: 1.0em; /* Adjust size to match h4 */
    font-family: sans-serif;
    letter-spacing: 0.08em;
  }
  
  .profileFeed-lengthIndicator {
    font-family: sans-serif;
    letter-spacing: 0.12em; /* Slightly spaced, but not bold */
    font-size: 0.9em; /* Slightly smaller than authorName */
    margin-right: 5px;
    padding-top: 6px;
  }
  
  .profileFeed-title-and-button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .profileFeed h3 {
    margin: 0; /* Remove margin to align with the button */
    flex-grow: 1; /* Allow the title to take up available space */
  }
  
  .profileFeed-read-piece-button {
    background-color: #E9DCD1;
    color: black;
    border: none;
    border-radius: 4px;
    padding: 7px 25px;
    text-align: center;
    cursor: pointer; /* Change cursor to pointer on hover */
    letter-spacing: 0.08em;
    margin-left: 10px;
  }
  
  .profileFeed-divider {
    border-bottom: 1px solid black;
    opacity: 0.1;
    margin-top: 20px; /* Space after the item before next starts */
  }
  
  .profileFeed-genre-style-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px; /* Space before the divider */
  }
  
  .profileFeed-genre-style-text {
    font-family: sans-serif;
    font-size: 14px;
    letter-spacing: 0.05em; /* Adjust for readability */
  }

  .profileFeed-optionsIcon {
    cursor: pointer;
  }
  
  .profileFeed-menu {
    position: absolute;
    background-color: #FFF9F2;
    border: 1px solid #ccc;
    border-radius: 4px;
    z-index: 1000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 8px;
    min-width: 100px;
    transform: translateX(-100%); /* Shift the menu left by 100% of its width */
  }
  
  .profileFeed-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .profileFeed-menu li {
    padding: 8px 12px;
    cursor: pointer;
  }
  
  .profileFeed-menu li:hover {
    background-color: #f0f0f0;
  }
  
  
  