.header-preview-piece-container {
    background-color: #E9DCD1;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    margin: 0;

    position: relative; /* or 'absolute', depending on your needs */
  }
  
  .header-preview-piece {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 575px;
    margin: auto; /* Center the container */
    padding: 20px; /* Add some padding */
  }
  
  .publish-piece {
    cursor: pointer;
    font-size: 17px;
    font-family: 'Prestige-Normal', sans-serif; /* Ensure font-family matches your setup */
    color: #000; /* Black color for active links */
    margin: 0 20px; /* Adds a bit of margin for spacing */

    text-decoration: underline;
  }
  
  .return-to-edit {
    cursor: pointer;
    font-size: 17px;
    font-family: 'Prestige-Normal', sans-serif; /* Ensure font-family matches your setup */
    color: #000; /* Black color for active links */
    margin: 0 20px; /* Adds a bit of margin for spacing */

  }

  .publish-piece.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  /* Additional styles for .discard if necessary */
  