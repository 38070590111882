.book-card {
    display: flex;
    align-items: center;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    padding: 10px;
  }
  
  .bookie-image {
    width: 140px; /* Adjust as needed */
    height: auto;
    margin-right: 20px;
  }
  
  .book-info {
    flex-grow: 1;
  }
  