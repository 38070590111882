.unsubscribe {
    background-color: #FFF9F2;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* .main-content {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  } */
  
  .unsubscribe-content {
    display: flex;
    justify-content: center;
    align-items: top;
    width: 100%;
    max-width: 900px;
    margin: 70px 0;
  }
/*   
  .footer {

    width: 100%;
    position: absolute;
    bottom: 0;
  }
   */
  