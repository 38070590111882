/* CreateAccountWriter.css */
.accountBox {
    border: 1px solid #d3d3d3;
    border-radius: 2px;
    padding: 20px;
    width: 350px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .inputContainer {
    width: 100%;
    padding-top: 20px;
  }
  
  .input {
    height: 40px;
    width: 100%;
    color: black;
    border: 1px solid gray;
    background-color: white;
    margin-bottom: 10px;
  }
  
  .joinButton {
    background-color: #E9DCD1;

    height: 40px;
    border: 0.1px solid black;
    border-radius: 2px;
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; 
  }
  
  .joinButtonText {
    font-weight: bold;
    color: black;
  }
  
  .separator {
    border-bottom: 1px solid #d3d3d3;
    margin: 10px 0;
    width: 100%;
  }
  
  .loginText, .sinfoText {
    color: black;
    text-align: center;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    margin: 7px 0px;

  }

  .sinfoText {
text-decoration: underline;
font-size: medium;

  }
  .joinButton:disabled {
    color: #888; /* Light gray text to indicate disabled state */
    cursor: default; /* Cursor change to indicate it's not clickable */
}
  
  .sloginOptions {
    display: flex;
    flex-wrap: wrap; 
    justify-content: space-between;
    width: 100%;
  }