.readers-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #E9DCD1;
  padding: 10px 0;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
}

.icons-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-width: 575px;
}

.footer-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.footer-item .label,
.footer-item .arrow {
  margin: 0;
  padding: 0;
}

.footer-icon {
  width: 56px;
  height: 56px;
  margin-bottom: 5px;
}

.footer-link {
  color: black;
  text-decoration: none;
}

.footer-link:hover .label {
  text-decoration: none;
}

.label-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  text-align: center;
}

.arrow {
  flex: 1;
  text-align: center;
  font-size: 12px;
}

.hidden {
  visibility: hidden;
}

.visible {
  visibility: visible;
}
