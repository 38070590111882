/* ConfirmSignUp.css */
.confirmationBox {
    border: 1px solid #d3d3d3;
    border-radius: 2px;
    padding: 20px;
    width: 350px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .input, .confirmButton {
    box-sizing: border-box;
    /* Other styles */
  }
  
  .inputContainer {
    width: 100%;
    padding-top: 20px;
  }
  
  .input {
    height: 40px;
    width: 100%;
    border: 1px solid gray;
    background-color: white;
    margin-bottom: 10px;
  }
  
  .confirmButton {
    background-color: #E9DCD1;
    height: 40px;
    border: 0.1px solid black;
    border-radius: 2px;
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .linkContainer {
    display: flex;
    width: 100%;
  }
  
  .linkTouch {
    flex: 1;
    text-align: center;
    color: black;
    cursor: pointer;
    background: none;
    border: none;
    padding: 5px;
  }
  
  .separator {
    border-bottom: 1px solid #d3d3d3;
    margin: 10px 0;
    width: 100%;
  }
  