.employee-dashboard {
    background-color: #FFF9F2;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .dashboard-content {
    display: flex;
    justify-content: space-around;
    width: 100%;
    max-width: 900px;
    margin: 70px 0;
    flex-grow: 1; 
    flex-direction: column-reverse;
    align-items: center;
  }
  