.modal-filter-genre {

    
    .modalContainer {
      /* existing styles */
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    
    .modalTitle {
      text-align: center;
    }
    
    .genreList {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    
    .genreItem {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 7px 0;
      cursor: pointer;
    }
    
    .label-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      position: relative;
    }
    
    .genreText {
      text-align: center;
      padding: 0 20px; /* Add some padding to prevent overlap with arrows */
    }
    
    .arrow {
      position: absolute;
      font-family: monospace;
      transition: opacity 0.3s ease;
      
      &.left {
        left: 0;
      }
      
      &.right {
        right: 0;
      }
      
      &.hidden {
        opacity: 0;
      }
      
      &.visible {
        opacity: 1;
      }
    }
    
    .infosButton {
      /* existing styles */
      align-self: center;
    }
  }