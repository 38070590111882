/* ApplicationPreview.css */
.applicationPreviewContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #FFF9F2;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
  max-width: 400px; /* Adjust as necessary */
  width: 87%;
  margin: auto;
  font-family: Arial, sans-serif;
}

.applicationActions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}


.button-application-return {
    background-color: #E9DCD1;
    color: black;
    border: none;
    padding: 10px 20px;
    border-radius: 2px;
    cursor: pointer;
    width: 100%;
    margin-top: 10px;

}

.button-application-apply {
    background-color: #E9DCD1;
    border: 0.1px solid rgb(76, 76, 76);
    color: black;
    border: none;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 2px;
    cursor: pointer;
    width: 100%;
    margin-top: 20px;
    cursor: pointer; 
    transition: opacity 0.1s;

}

.button-application-apply:disabled {
  color: #666666; /* Dark gray text color when disabled */
  cursor: default; /* Cursor to indicate the button is not clickable */
}


