.actionRowFullWidth {
    display: flex;
    width: 100%;
    gap: 10px;
    align-items: stretch; /* Ensure buttons align */
  }
  
  /* 
     Each child in the row is half the total width 
     so the two items together fill 100%. 
  */
  .halfWidthItem {
    flex: 1; /* Ensures equal width and height */
  }

  .fullWidthItem {
    width: 100% !important;
    margin-top: 10px;
  }
  
  
  /* Make the dropdown look more like your buttoninbox style */
  .bookStatusDropdownWrapper {
    position: relative; /* This ensures dropdown positions itself relative to this */
    width: 100%; /* Match button width */
}
  
  /* Dropdown trigger button */
  .bookStatusDropdownTrigger {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between; /* Ensures text and triangle are separate */
    text-align: left;
    padding-right: 2rem; /* Space for triangle */
  }

  .bookStatusDropdownTrigger.inactive {
    color: #A0A0A0; /* Light gray text */
    font-weight: bold; /* Keep it bold */
}

.bookStatusDropdown li.selected {
    font-weight: bold;
}


  .bookModalContent {
    display: flex;
    flex-direction: column; /* Stack header and content vertically */
    width: 100%; /* Ensure the modal takes full width */
    box-sizing: border-box; /* Include padding in width calculations */
  }
  
  .modalHeader {
    display: flex;
    justify-content: flex-end; 
    align-items: center; /* Centers it vertically */
    width: 100%; /* Ensures it spans the full modal width */
    margin:3px 5px;
    margin-top: -10px;
    box-sizing: border-box; /* Ensures padding doesn't affect width */
  }
  
  .bookModalCloseButton {
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .bookModalCloseButton img {
    width: 21px; /* Adjust as needed */
    height: 21px;
  }
  
  /* Triangle inside the button */
  .bookStatusDropdownTriangle {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
    pointer-events: none;
  }
  
  /* Dropdown menu */
  .bookStatusDropdown {
    position: absolute;
    top: calc(100% + 5px); /* Places it directly below the button */
    left: 0px; /* Aligns it with the button */
    background-color: #FFF9F2;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding: 8px 0;
    width: 100%; /* Ensures it matches button width */
}


  /* List inside dropdown */
  .bookStatusDropdown ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  /* List items */
  .bookStatusDropdown li {
    padding: 8px 16px;
    cursor: pointer;
    font-size: small;
  }
  
  /* Hover effect */
  .bookStatusDropdown li:hover {
    background-color: #D3C4B6;
  }
  

  .bookStatusDropdownTrigger {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center; /* Centers text horizontally */
    text-align: center;
    width: 100%; /* Ensures it fills the container */
    padding: 10px 2rem 10px 2rem; /* Left padding to balance space with the triangle */
}

.bookStatusDropdownTrigger span {
    flex-grow: 1; /* Makes the text take up remaining space */
    text-align: center;
}

/* Triangle stays on the right */
.bookStatusDropdownTriangle {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
    pointer-events: none;
}
