/* CreateAccountBox.css */
.createAccountBox {
    border: 1px solid #d3d3d3;
    border-radius: 2px;
    padding: 20px;
    width: 350px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
  }
  
  .createText {
    color: black;
  }
  
  .createLink {
    font-weight: bold;
    text-decoration: underline;
    color: black;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
  }

  .submitButtonCreate {
    background-color: #E9DCD1;
    height: 40px;
    border: 0.1px solid black;
    border-radius: 2px;
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.2s ease;
    cursor: pointer;
    color:black;
  }
  