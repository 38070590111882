.job-submit {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0px 0; /* add some vertical margin to separate the form from other content */
}

.email-form {
    display: flex;
    justify-content: space-between;
    width: 90%;
    padding: 2px;
    max-width: 400px; /* make the form narrower */
}

.email-input, .submit-button {
    box-sizing: border-box;
    padding: 2px 5px; 
    font-size: 0.8rem;
    border-radius: 5px; /* make the corners more rounded */
    border: 1px solid #d3d3d3;
    width: 100%;
}

.email-input {
    flex-grow: 1; /* allow the email input to take up as much space as available */
    margin-right: 20px; /* add more space between the input and the button */
}



  
  .submit-button {
    background-color: #E9DCD1;
    color: black;
    border: 0.1px solid; /* matching border for the button */
    border-radius: 2px; /* beveled corners for the button */
    padding: 0px;
    text-align: center; /* center the button text */
  }