.header-main {
    font-family: Prestige-Normal;
    font-size: 24px;
    text-align: center;
    background-color: #E9DCD1;

    color: #30221C;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    margin: 0; 
    padding: 20px 0;
    letter-spacing: 4px; 
    position: relative;

  }