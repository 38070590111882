.ri-dashboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding-top: 20px; */
    width: 100%;
    min-height: 85vh;
    overflow-y: auto;
}

.ri-dashboard-content {
    margin: 0 20px;
    width: 100%;
    max-width: 450px;
    box-sizing: border-box;
    padding: 20px;
}

.ri-readers-list-items {
    list-style-type: none;
    padding: 0;
}

.ri-dashboard-title {
    font-size: 28px;
    color: #2F2B27;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    font-family: sans-serif;
    letter-spacing: 0.05em;
}

.ri-dashboard-metrics-container {
    width: 100%;
    margin-bottom: 30px;
    margin-top: 30px;
}

.ri-time-selector {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;
}

.ri-time-button {
    background-color: #E9DCD1;
    height: 35px;
    border: 0.1px solid rgb(76, 76, 76);
    border-radius: 2px;
    cursor: pointer;
    padding: 0 15px;
    font-size: 14px;
    letter-spacing: 0.05em;
    transition: opacity 0.1s;
}

.ri-time-button.active {
    color: black;
}

.ri-time-button:not(.active) {
    color: #888;
}

.ri-time-button:hover {
    opacity: 0.8;
}

.ri-metrics-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    width: 100%;
    margin-top: 20px;
}

.ri-metric-block {
    padding: 10px 20px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.ri-metric-value {
    font-size: 2.2em;
    margin: 0;
    font-weight: bold;
    text-align: center;
}

.ri-writer-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black;
    width: 100%;
}

.ri-writer-link:hover {
    text-decoration: none;
    color: black;
}

.ri-metric-label {
    margin: 0;
    font-size: 0.9em;
    color: #666;
    text-align: center;
}

.ri-writer-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
    flex-shrink: 0;
}

.ri-writers-list {
    margin-top: 30px;
    width: 100%;
}

.ri-writers-list-title {
    font-size: 16px;
    font-weight: bold;
    color: black;
    width: 100%;
    margin-bottom: 15px;
    letter-spacing: 0.05em;
}

.ri-writers-list-items {
    list-style-type: none;
    padding: 0;
    width: 100%;
}

.ri-writer-item {
    font-size: 16px;
    color: black;
    margin-bottom: 10px;
    letter-spacing: 0.05em;
    line-height: 1.25;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ri-reader-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ri-reader-name, .ri-read-count, .ri-read-time {
    display: inline;
    color: black;
    text-decoration: none;
}

.ri-read-count::before,
.ri-read-time::before {
    content: " ";
    white-space: pre;
}

.subtle-line {
    border-bottom: 1px solid #d3d3d3;
    margin-top: 20px;
    width: 100%;
}

.ri-return-home-button {
    background-color: #E9DCD1;
    height: 35px;
    border: 0.1px solid rgb(76, 76, 76);
    border-radius: 2px;
    cursor: pointer;
    margin-top: 30px;
    width: calc(100%);
    max-width: 435px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.1s;
    box-sizing: border-box;
    font-size: 14px;
    letter-spacing: 0.05em;
    margin-left: auto;
    margin-right: auto;
}

.ri-return-home-button:hover {
    opacity: 0.8;
}