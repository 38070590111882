.about-words {
    max-width: 800px;
    margin: 20px auto;
    padding: 0 20px;
    box-sizing: border-box;
    width: 100%;

  }
  
  .about-words h1 {
    margin-bottom: 20px;
  }
  
  .about-words p {
    line-height: 1.6;
    margin-bottom: 20px;
  }

  .about-words {
    color: black;
  }
  
  .about-words h3 {
    color: black;
    margin-bottom: 20px;
  }
  .subtle-line-aboutwords {
    border: 0;
    height: 1px;
    background-color: #ccc;
    margin: 20px 0;
  }

  .cta-button {
    background-color: #E9DCD1;
    height: 35px;
    border: 0.1px solid rgb(76, 76, 76);
    border-radius: 2px;
    cursor: pointer;
    margin-top: 15px;
    margin-bottom: 20px;
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    transition: opacity 0.1s;
    box-sizing: border-box;
    font-size: 16px;
    letter-spacing: 0.05em;
    color: black;
}
  
  .about-list {
    list-style-type: none;
    padding: 0;
  }
  
  .about-list li {
    margin-bottom: 20px;
    padding-left: 30px;
  }
  
  .about-list a {
    color: black;
    text-decoration: underline; 
  }
  
  .about-list a:hover {
    text-decoration: underline;
  }

  .image-birdie-container {
    display: flex;
    justify-content: center;
    margin: 40px 0;
  }
  
  .centered-birdie-image {
    max-width: 80%;
    height: auto;
  }

  .centered-birdie-imagess {
    max-width: 100%;
    height: auto;
  }

  .about-words .clickable-link {
    color: black;  /* Traditional link color, you can change this */
    text-decoration: underline;
    cursor: pointer;
  }

  