.unsubscribe-box {
    border: 1px solid #d3d3d3;  
    border-radius: 2px;
    font-size: 0.9rem;
    padding-left: 20px;
    padding-right: 20px;
    width: 250px;
    display: inline-block; 
    margin-bottom: 10px;
    flex-direction: column;
    align-items: center;
  }
  
  .unsubscribe-text {
    margin-bottom: 20px;
  }
  
  .unsubscribe-inputs, .unsubscribe-buttons {
    margin-bottom: 0px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .unsubscribe-inputs {
    padding: 10px;
  }
  
  .submit-buttons {
    background-color: #E9DCD1;
    color: black;
    border: 0.1px solid;
    border-radius: 2px;
    text-align: center;
    margin-bottom: 30px;
  }

  .form-container {
    display: block; /* Makes the container as big as it needs to be */
    width: auto;
  }