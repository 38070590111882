/* ApplyWriterFlow.css */
.pendingWriterContainer {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Ensures container is at least as tall as the viewport */
    background-color: #FFF9F2; /* Your background color */
}

.pendingWriterContent {
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 20px; /* Padding around the content */
}
