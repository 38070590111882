.modalesque {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding-top: 120px;
    width: 100%;
  }
  
  .contentBoxes {
    margin: 0 auto; 
    border: 1px solid #d3d3d3; 
    width: 100%; /* Adjusted to the desired max width */
    max-width: 440px;
    box-sizing: border-box; 


    


    padding: 20px;

  }

  .button-separatores {
    height: 1px;
    background-color: #d3d3d3;
    margin: 15px 0;
    width: 100%;
  }
  
  .titleContainerey {
    align-items: left; 
    width:100%;
    margin-top: 0px;
    margin-bottom: 20px;
  }
  
  .modalTitley {
    font-size: 24px;
    margin-top: 0px;
    color: #2F2B27;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    margin-bottom: 10px;
    font-family: sans-serif;
    letter-spacing: 0.5px;

    
  }
  
  .image-previews {
    width: 100%;
    height: auto;
    margin-bottom: 9px;
  }
  
  .fileUploadContainer, .buttonRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between; /* Space out buttons */
    width: 100%; /* Fill container width */
    margin-bottom: 20px;
    gap: 10px; 
  }
  
  
  .fileInput {
    margin-bottom: 10px;
  }
  
  .infosButton {
    background-color: #E9DCD1;
    color: black;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .infosButton:hover {
    opacity: 0.9;
  }
  