
  
  .modal-filter-lives .modalTitle {
    margin-bottom: 20px;
  }
  
  .modal-filter-lives .sectionTitle {
    margin-top: 15px;
    margin-bottom: 10px;
  }
  
  .modal-filter-lives .dropdown {
    margin-bottom: 15px;
    margin-top: 7px;
  }
  
  .modal-filter-lives .infosButton {
    margin-top: 20px;
  }