.piecepage {
  background-color: #FFF9F2;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /* Remove padding from here */
}

.contents-container-piece {
  width: 100%; /* Full width */
  max-width: 575px; /* Adjust as needed */
  margin: 0 auto; /* Centering */
  padding: 20px; /* Padding for content */
  box-sizing: border-box;
  flex-grow: 1; /* Allow it to grow */
}

.italic-text {
  font-style: italic;
}

.promoted-books-text {
  text-align: right; /* Aligns the text to the right */
  font-size: 18px; /* Sets a small font size for subtlety */
  color: #666; /* A mild color for subtlety */
  margin-right: 20px; /* Adjusts the right margin for alignment */
  margin-bottom: 10px;
}

/* Media query for smaller screens */
@media (max-width: 600px) {
  .content-container {
    padding: 15px;
  }
}

.divider-piece {
  border-bottom: 1px solid black;
  opacity: 0.1;
  margin: 14px 0;
}

.genre-style-container-piece {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px; /* Space before the divider */
  align-self: center;
}

.genre-style-text-piece {
  /* Add styles for genre and style text here */
  font-family: sans-serif;
  /* Other styling as needed */
}


  .piecepage-words-piece {
    display: flex;
    justify-content: space-around;
    width: 100%;
    max-width: 640px;
    /* margin: 10px 0; */
    flex-grow: 1;
  
    /* Add the text styling properties here */
    font-family: sans-serif; /* Changes the font to sans-serif */
    text-align: justify; /* Justifies the alignment */
    letter-spacing: 0.05em; /* Increases the space between letters */
  }
  
  .footer {
    flex-shrink: 0;
  }

  .piece-image {
    width: 100%; /* Makes the image responsive */
    max-width: 640px; /* Adjust based on the max width of your content */
    margin: 10px 0;
    flex-grow: 1;
    margin-top: 0px;
  }

  .piecewords {
    width:100%
  }

  .piece-author-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px; /* Space before the piece content */
  }

  .piece-author-only {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px; /* Space before the piece content */
  }

  .piece-author-name {
    font-weight: bold;
    font-size: 1.0em;
    font-family: sans-serif;
    letter-spacing: 0.08em;
  }

  .piece-author-photo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 0.5rem;
    object-fit: cover; 

  }

  .serial-navigation {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 20px 0;
    width: 100%;
  }
  
  .serial-nav-link {
    color: black;
    cursor: pointer;
    flex: 1;
  }
  
  .serial-nav-link span {
    display: inline-block;
    text-align: center;
  }
  
  .serial-nav-link .arrow {
    vertical-align: middle;
  }
  
  .serial-nav-link .main-text {
    vertical-align: left;
    font-size: 0.9em;
    margin-top: 5px;
  }
  
  .serial-nav-link .sub-text {

    display: block;
    font-size: 0.9em;
    margin-top: 5px;
  }
  
  .serial-nav-link.previous {
    text-align: left;
  }
  
  .serial-nav-link.next {
    text-align: right;
  }
  
  .serial-nav-link:hover .main-text {
    /* Add hover styles here if needed */
  }
  /* Wrapper section for the like and create account buttons */
.piece-like-section {
  margin: 20px 0;
  text-align: center;
}

/* Base button styling for this piece component */
.piece-action-button {
  background-color: #E9DCD1;
  height: 35px;
  border: 0.1px solid rgb(76, 76, 76);
  border-radius: 2px;
  cursor: pointer;
  margin-top: 15px;
  margin-bottom: 20px;
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  transition: opacity 0.1s ease, transform 0.1s ease;
  box-sizing: border-box;
  font-size: 16px;
  letter-spacing: 0.05em;
  color: black;
}

.piece-action-button-unchosen {
  color: rgb(150, 150, 150); /* Light gray text for unchosen */

  opacity: 0.9; /* Slightly muted */
}

/* Hover effect for unchosen state (implies interactivity) */
.piece-action-button-unchosen:hover {
  opacity: 1; /* Brightens up */
  transform: scale(1.02); /* Slight scaling effect */
}

/* Liked state (bold and clear) */
.piece-action-button-liked {
  color: black; /* Darker text for emphasis */
  font-weight: bold; /* Bold for clarity */
  transform: scale(1.02); /* Slight pop effect */
  animation: pop-hearts 0.3s ease; /* Satisfying animation */
}

.piece-create-account-text {
  margin-bottom: 12px;
  font-size: 14px;
  color: rgb(76, 76, 76);
}
.piece-share-icon{  
  width: 14px;
  height: 14px;
  margin-left: 8px;
  vertical-align: middle;}

@keyframes pop-hearts {
  0% {
    transform: scale(0.8); /* Start smaller */
    opacity: 0;
  }
  50% {
    transform: scale(1.2); /* Slightly larger */
    opacity: 1;
  }
  100% {
    transform: scale(1); /* Normal size */
  }
}

.piece-action-button:hover {
  opacity: 0.8;
  transform: scale(1.02);
}

/* Specific style for liked state */
.piece-action-button-liked {
  background-color: #E9DCD1;
  height: 35px;
  border: 0.1px solid rgb(76, 76, 76);
  border-radius: 2px;
  cursor: pointer;
  margin-top: 15px;
  margin-bottom: 20px;
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  transition: opacity 0.1s ease, transform 0.1s ease;
  box-sizing: border-box;
  font-size: 16px;
  letter-spacing: 0.05em;
  color: black;
}

/* Non-logged-in create account button */
.piece-create-account-button {
  background-color: #E9DCD1;
  height: 35px;
  border: 0.1px solid rgb(76, 76, 76);
  border-radius: 2px;
  cursor: pointer;
  margin-top: 15px;
  margin-bottom: 20px;
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  transition: opacity 0.1s ease, transform 0.1s ease;
  box-sizing: border-box;
  font-size: 16px;
  letter-spacing: 0.05em;
  color: black;
}

.piece-create-account-button:hover {
  background-color: #E9DCD1;
  height: 35px;
  border: 0.1px solid rgb(76, 76, 76);
  border-radius: 2px;
  cursor: pointer;
  margin-top: 15px;
  margin-bottom: 20px;
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  transition: opacity 0.1s ease, transform 0.1s ease;
  box-sizing: border-box;
  font-size: 16px;
  letter-spacing: 0.05em;
  color: black;
}

.piece-copied-tooltip {
  position: absolute;
  bottom: 45px; /* Position above the button */
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 3px;
  opacity: 1;
  animation: fadeOut 0.5s ease forwards;
}

.piece-share-section {
  margin: 20px 0;
  text-align: center;
  position: relative; /* Ensures the tooltip is positioned relative to this container */
}

