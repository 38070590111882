.descriptive-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: auto;
}

.descriptive-title {
    font-size: 16px;
    font-weight: bold;
    color: black;
    width: 100%;
}

.descriptive-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 15px;
}

.descriptive-style,
.descriptive-genre {
    font-size: 14px;
    color: black;
}

.descriptive-blank {
    font-style: italic;
    font-size: 14px;
    color: black;
}
