.header-edit-profile-container {
    background-color: #E9DCD1;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    margin: 0;

    position: relative; /* or 'absolute', depending on your needs */
  }
  
  .header-edit-profile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 575px;
    margin: auto; /* Center the container */
    padding: 20px; /* Add some padding */
  }
  
  .link {
    cursor: pointer;
    font-size: 19px;
    font-family: 'Prestige-Normal', sans-serif; /* Ensure font-family matches your setup */
    color: #000; /* Black color for active links */
    margin: 0 10px; /* Adds a bit of margin for spacing */
    margin-left: 30px;
    margin-right: 30px;
  }
  
  .link.save {
    color: #ccc; /* Default grey color */
    text-decoration: none; /* No underline by default */
  }
  
  .link.save.active {
    color: #000; /* Black color for active state */
    text-decoration: underline; /* Underline for emphasis */
  }
  
  /* Additional styles for .discard if necessary */
  