.homepage {
  background-color: #FFF9F2;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.homepage-content {
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 900px; /* adjust this value to suit your design */
  margin: 70px 0; /* add some vertical margin to separate the Header and the content */
  flex-grow: 1; 
}

.footer {
  /* ... your footer styles ... */
  width: 100%;
  position: absolute;
  bottom: 0;
}


@media (max-width: 1000px) {
  .screenshots-wrapper {
    display: none;
  }

  .homepage-content {
    flex-direction: column-reverse;
    align-items: center;
  }
}


