/* AppFormIntro.css */
.introContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.contentView {
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    padding: 20px;
    max-width: 350px; /* Adjust as needed */
    text-align: center; /* Center align content */
    font-family: Arial, sans-serif; /* Change the font family */
}

.text, .bold {
    color: black;
    margin-bottom: 20px;
    font-family: Arial, sans-serif; /* Consistent font family */
}

.bold {
    font-weight: bold;
    font-size: 18px;
}

.applyButton, .signOutButton {
    background-color: #E9DCD1;
    color: white;
    border: none;
    padding: 10px 40px;
    border-radius: 2px;
    cursor: pointer;
    width: 100%; /* Make buttons extend full width */
    box-sizing: border-box; /* Ensure padding does not affect width */
    color:black;
}

.applyButton {
    font-weight: bold;


}

.separatored {
    border-bottom: 1px solid #d3d3d3;
    margin: 20px 0;
}

/* Optional: If you want to use the same styles as .about-words for paragraphs */
.contentView p {
    line-height: 1.6;
    margin-bottom: 20px;
    text-align: left; /* Left align text in paragraphs */
}

.spinfoText {
    color: black;
    text-align: center;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    text-decoration: underline;



  }
