.footer-create {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 20px 0;
    position: fixed;
    bottom: 0;
    background-color: #FFF9F2; /* same as your homepage background color */
  }
  
  .writer-footer-link {
    color: #000; /* change to the color you want for the links */
    font-family: Arial, sans-serif;

    font-size: 1em; /* smaller size */
    text-decoration: none; /* remove underline */
    margin: 0 10px; /* add a break between the links */
  }