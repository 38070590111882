.toolbar {
    display: flex;
    justify-content: flex-start;
    background-color: #E9DCD1;
    padding: 8px;
    gap: 6px; /* Adjusts space between buttons */
  }
  
  .toolbar button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #ccc; /* Default icon color */
  }
  
  .toolbar button.active {
    color: black; /* Active icon color */
  }
  
  /* You might need to adjust the icon size based on your design */
  .material-icons {
    font-size: 20px;
  }
  