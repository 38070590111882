.application-table-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* or your preferred height */
}

.table-container {
    overflow-y: auto;
    max-height: 70%; /* Adjust based on your content */
}

.applications-table {
    width: 100%;
    text-align: center;
}



tr {
    background-color: #add8e6;
  }
  
  /* Only apply this background color to rows with the .selected class */
  tr.selected {
    background-color: #009688; /* Or your chosen highlight color */
  }
