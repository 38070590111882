.jobpage {
    background-color: #FFF9F2;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; /* Aligns content to start */

    padding-bottom: 80px; /* Adjust based on the height of your footer */
  }

  .jobpage-content {
    display: flex;
    justify-content: space-around;
    width: 100%;
    max-width: 650px;
    margin: 10px 0;
    flex-grow: 1;
  
    /* Add the text styling properties here */
    font-family: sans-serif; /* Changes the font to sans-serif */
    text-align: justify; /* Justifies the alignment */
    letter-spacing: 0.05em; /* Increases the space between letters */
  }
  
  .footer {
    flex-shrink: 0;
  }