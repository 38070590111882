/* CreateAccountBox.css */
.createAccountBox {
    border: 1px solid #d3d3d3;
    border-radius: 2px;
    padding: 20px;
    width: 350px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
  }
  
  .createText {
    color: black;
    font-weight: bold;
  }
  
  .createLink {
    font-weight: bold;
    text-decoration: underline;
    color: black;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
  }
  