/* ProfileSectionContainer.css */
.profileSections {
    /* Styles for the overall container, if any */
  }
  
  .sectionContainer {
    border: 1px solid #EAEAEA;
    border-radius: 6px;
    margin-bottom: 20px;
    padding: 16px;
  }
  
  .sectionTitle {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 12px;
  }
  
  .fieldRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 8px 0;
  }
  
  .fieldLabel {
    font-weight: normal;
    color: #333;
  }
  
  .fieldValue {
    color: #666;
  }
  