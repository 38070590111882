.ttreusableBoxContainered {

    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none; /* Removes underline */
  color: black; 
  }

  .ttreusableBoxContainered:hover {
    cursor: pointer; /* Shows a pointer cursor on hover to indicate it's clickable */
  }

  
  
  .tcontentedViewsomuchInBox {
    width: 100%;
    padding: 10px;
    border: 1px solid #d3d3d3;
    position: relative; /* Ensure the container is positioned relatively */
    text-align: center;
    margin-bottom: 20px;
  }
  
  
  .tboldsterInBox {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 0px;
    font-family: sans-serif;
  }
  
  .tnormailerInBox {
    font-weight: normal;
    font-size: 18px;

    margin-top: 10px;
    font-family: sans-serif;
    font-style: italic;
  }
  

  