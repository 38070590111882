.modalsTitles {
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 0px;
    font-family: sans-serif;
  }

  .modalesContaineres {
    background: #FFF9F2;
    padding: 10px 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 450px;
    overflow-y: auto;
    font-family: 'sans-serif';
  }
  .table-container {
    width: 100%;
    border: 1px solid #d3d3d3;
  }
  .header-cell {
    border-bottom: 1px solid #d3d3d3;
    border-right: 1px solid #d3d3d3; /* Adds vertical line */
    padding: 8px;
    text-align: center;
    background-color: #E9DCD1; /* Light gray background for header */
    font-weight: normal;
    font-size: medium;
  }
  
.scrollable-container {
  height: 100px; /* Or whatever height you prefer */
  overflow-y: auto; /* This will enable scrolling */
  background-color: white;
  border: 1px solid #d3d3d3;
}

.results-table {
  width: 100%;
  border-collapse: collapse;
}

.result-item {
  border-bottom: 1px solid #d3d3d3;
  cursor: pointer; /* Indicates this item can be clicked */
  background-color: #FFF; /* Background color for table row */
  font-family: sans-serif;
  font-size: small;
}

.result-item:hover {
  background-color: #f0f0f0; /* Slight color change on hover */
}

.result-cell {
  padding: 10px; /* Padding within each cell */
  text-align: left; /* Align text to the left side */
}

.kinfosButton-detailss {
    background-color: #E9DCD1;
    height: 35px;
    font-weight:bold;
    border: 0.1px solid rgb(76, 76, 76);
    border-radius: 2px;
    cursor: pointer; /* Indicate it's clickable */
    margin-bottom: 20px;
    margin-top: 0px;
  
    width: 100%; /* Fill the container width */
    justify-content: center;
    display: flex;
    align-items: center;
    transition: opacity 0.1s;
    box-sizing: border-box;
  }

  .infosButton-detailss {
    background-color: #E9DCD1;
    height: 35px;
    border: 0.1px solid rgb(76, 76, 76);
    border-radius: 2px;
    cursor: pointer; /* Indicate it's clickable */
    margin-bottom: 20px;
    margin-top: 0px;
  
    width: 100%; /* Fill the container width */
    justify-content: center;
    display: flex;
    align-items: center;
    transition: opacity 0.1s;
    box-sizing: border-box;
  }

  .userName-normal {
    font-weight: normal;
  }

  .spaces-writing-name {
    margin-top: 20px;
  }


  .spaces-writing-names {
    margin-top: 10px;
  }

  .infosButton-details-next {
    background-color: #E9DCD1;
    height: 35px;
    border: 0.1px solid rgb(76, 76, 76);
    border-radius: 2px;
    cursor: pointer; /* Indicate it's clickable */
    margin-bottom: 15px;
    margin-top: 20px;

  
    width: 100%; /* Fill the container width */
    justify-content: center;
    display: flex;
    align-items: center;
    transition: opacity 0.1s;
    box-sizing: border-box;
  }

  .horizontal-feed {
    display: flex;
    overflow-x: auto;
    padding: 10px;
  }
  
  .forbreaks {
    margin-bottom: 0px;
    margin-top: 5px;
  }
  
  .book-item {
    flex: 0 0 auto;
    margin-right: 17px;
     /* Adjust based on your needs */
  }
  
  .books-images {

    border-radius: 8px;
    width: 100px; /* Set a specific width. */
    height: 140px; /* Set a specific height. This example assumes a 2:3 width to height ratio, common for book covers. */


  }

  .book-item.selected-book-item {
    border: 2px solid gray; /* Example: blue border for selected items */
    border-radius: 4px; /* Optional: rounded corners for the border */
    padding: 2px;
  }

  .result-item.selected-book {
    font-weight: bold;
  }
  
  .book-details {
    text-align: center;
    margin-top: 5px;
    white-space: nowrap; /* Prevents the text from wrapping */
    overflow: hidden; /* Hide the overflow content */
    text-overflow: ellipsis; /* Show an ellipsis (...) where the text is cut off */
    width: 100px; /* Ensure this matches the width of your book images for alignment */
  }
  
  .modalsTitles, .sectionTitle,  div {
    font-family: sans-serif; /* Example of specifying a fallback font */

}
.forbreaks-hopes {
  margin-bottom: 10px;
  margin-top: 10px;
}

.book-details div {
    font-family: sans-serif; 
    font-size: small;/* Example of specifying a fallback font */
}
.dropdown div {
    font-family: sans-serif; 
    font-size: small;/* Example of specifying a fallback font */
}

.tags-dividers {
    border-bottom: 1px solid #000; /* Make it a bit thicker and ensure the color is solid black */
    opacity: 0.2; /* Ensure it's fully opaque */
    width: 100%; /* Ensure it spans the full width */
    margin-top: 20px;
    margin-bottom: 20px;
    align-items: center;

}