.writer-publish-type {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 85vh;
    overflow-y: auto;
}

.writer-publish-type__title {
    font-size: 24px;
    color: #2F2B27;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    font-family: sans-serif;
    letter-spacing: 0.05em;
}

.writer-publish-type__section {
    margin: 0 20px;
    border: 1px solid #d3d3d3;
    width: 100%;
    max-width: 450px;
    box-sizing: border-box;
    padding: 20px;
    margin-bottom: 20px;
}

.writer-publish-type__section-title {
    font-size: 16px;
    font-weight: bold;
    color: black;
    width: 100%;
    margin-bottom: 15px;
    letter-spacing: 0.05em;
}

.writer-publish-type__button {
    background-color: #E9DCD1;
    height: 35px;
    border: 0.1px solid rgb(76, 76, 76);
    border-radius: 2px;
    cursor: pointer;
    margin-top: 15px;
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    transition: opacity 0.1s;
    box-sizing: border-box;
    font-size: 16px;
    letter-spacing: 0.05em;
}

.writer-publish-type__button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.writer-publish-type__slider {
    overflow-x: auto;
    white-space: nowrap;
    margin-bottom: 15px;
    /* Add more styling as needed for your slider */
}

/* New CSS for the serial feed */
.writer-publish-type__serial-feed {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    margin-bottom: 15px;
    padding: 10px;
}

.writer-publish-type__serial-item {
    flex: 0 0 auto;
    margin-right: 15px;
    cursor: pointer;
    transition: transform 0.2s;
    width: 120px;
    padding: 2px;  
}

.writer-publish-type__serial-item:hover {
    transform: scale(1.05);
}

.writer-publish-type__serial-item.selected {
    border: 2px solid #2F2B27;
    border-radius: 4px;
}

.writer-publish-type__serial-image {
    width: 100%;
    height: 120px;
    object-fit: cover;
    border-radius: 4px;
}

.writer-publish-type__serial-details {
    margin-top: 5px;
    text-align: center;
}

.writer-publish-type__serial-title {
    font-size: 14px;
    font-weight: bold;
    color: #2F2B27;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 2.8em;
    line-height: 1.4em;
}

.writer-publish-type__serial-info {
    font-size: 12px;
    color: #666;
    margin-top: 3px;
}

/* Scrollbar styling for the feed */
.writer-publish-type__serial-feed::-webkit-scrollbar {
    height: 6px;
}

.writer-publish-type__serial-feed::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.writer-publish-type__serial-feed::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
}

.writer-publish-type__serial-feed::-webkit-scrollbar-thumb:hover {
    background: #555;
}