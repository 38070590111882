.infosButton {
    background-color: #E9DCD1;
    height: 35px;
    border: 0.1px solid rgb(76, 76, 76);
    border-radius: 2px;
    cursor: pointer; /* Indicate it's clickable */
    margin-bottom: 5px 0;
    margin-top: 10px;
  
    width: 100%; /* Fill the container width */
    justify-content: center;
    display: flex;
    align-items: center;
    transition: opacity 0.1s;
    box-sizing: border-box;
  }


  
  .modalContainer {
    background: #FFF9F2;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 450px;
    overflow-y: auto;
  }
  
  .modalTitle {
    font-weight: bold;
    font-size: 16px;
    color: black;
    text-align: center;

  }
  
  .genreList {
    display: flex;
    flex-direction: column;
  }
  
  .genreItem {
    padding: 8px;
    border-bottom: 1px solid #E9DCD1;
    cursor: pointer;
  }
  
  .genreText {
    font-size: 14px;
    font-family: sans-serif;
    text-align: center;
    display: block;
    color: gray; 
  }
  
  .selected .genreText {
    color: black; /* Selected genre color */
  }