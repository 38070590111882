.privacy-policy-words {
    max-width: 800px;
    margin: 20px auto;
    padding: 0 20px;
    box-sizing: border-box;
  }
  
  .privacy-policy-words h1 {
    margin-bottom: 20px;
  }
  
  .privacy-policy-words p {
    line-height: 1.6;
    margin-bottom: 20px;
  }



  .privacy-policy-words li {
    line-height: 1.6;
    margin-bottom: 20px;
  }
