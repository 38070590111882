.screenshot-page {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #FFF9F2;
    min-height: 100vh;
    width: 100%;
    padding-bottom: 80px;
    
  }
  
.screenshotspage-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  padding: 0 20px;
  font-family: sans-serif;
  text-align: justify;
  letter-spacing: 0.05em;
}
  
.app-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%; 
    margin: 60px 20px; 
  }
  
  .screenshot-group {
    display: flex;
    /* flex-direction: row; */
    justify-content: center;
    width: 100%;
    margin-top: 20px; /* Add this */
    
  }
  
  .screenshot {
    max-width: 210px;
    width: 100%;
    margin-bottom: 20px;
    margin-right: 20px; /* Adjust this */
    margin-left: 20px;  /* Add this */
  }
  
  .footer {
    flex-shrink: 0;
  }
  
  
  @media (max-width: 768px) {
    .screenshotspage-content {
      flex-direction: column;
      align-items: center;
      justify-content: center; 
    }
  
    .app-group {
      width: 100%;
      margin: 20px 0; 
    }

    .screenshot-group {
        flex-direction: column; /* Add this */
        align-items: center;
        justify-content: center; 
      }
    
    .screenshot {
        margin-right: 0; /* Add this */
        margin-left:0;
      }
  }