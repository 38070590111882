/* HeaderAppFlow.css */
.headersContainers {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: #E9DCD1;
}

.iconsContainers {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 455px; /* Limit the width of the icon container */
}

.stepsies {
    border: none;
    background: none;
    cursor: pointer;
}

.arrows, .visible, .hidden {
    margin-left: 0px;
    color: black;
}

 .visible, .hidden {
    margin-left: 20px;
    color: black;
}

.visible {
    opacity: 1;
}

.hidden {
    opacity: 0;
}

.activeIcon, .inactiveIconsies {
    width: 60px;
    height: 60px;
}

.activeIcon {
    filter: none;
}

.inactiveIconsies {
    opacity: 0.3;
}
