.homeSettingsModalContainer {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .homeSettingsModalOptions {
    background: #FFF9F2;
    width: 100%;
    padding: 20px;
  }
  
  .homeSettingsModalTitle {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
  }

  .homeSettingsModalsTitle {
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
  }

  .homeSettingsModalActionTitle {
    margin-bottom: 10px;
    margin-top: 20px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
  }

  .homeSettingsModalActionLink {
    margin-bottom: 10px;
    margin-top: 20px;
    font-size: 16px;
    font-weight: normal;
    cursor: pointer;
  }
  
  .homeSettingsModalButton {
    display: block;
    width: 100%;
    background: none;
    border: none;
    text-align: left;
    padding: 10px 20px;
    color: #333;
    font-size: 16px;
    cursor: pointer;
    outline: none;
  }
  
  .homeSettingsModalButton:hover {
    background-color: #f8f8f8;
  }
  
  .homeSettingsModalButton.selected {
    font-weight: bold;
    color: black;
  }