.custom-link-bs {
    color: black;
    text-decoration: underline;
  }

  .bsfaqs {
    max-width: 800px;
    margin: 20px auto;
    padding: 0 20px;
    box-sizing: border-box;
  }
  
  .bsfaqs h1 {
    margin-bottom: 20px;
  }
  
  .bsfaqs p {
    line-height: 1.6;
    margin-bottom: 20px;
  }



  .bsfaqs li {
    line-height: 1.6;
    margin-bottom: 20px;
  }
