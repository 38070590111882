.enhanced-book-card {
  display: flex;
  align-items: center;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px; 
}

.books-image {
  width: 100px; 
  height: 150px; 
  margin-right: 20px;
}

.books-info {
  flex-grow: 1;
  font-size: 14px;
}

