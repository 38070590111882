.editProfileContainer {
    overflow-y: auto;

    background-color: #FFF9F2;
    min-height: 100vh; /* Ensure it covers the whole viewport height */

  
  
    
  }
  

  .editProfileContainer {
    display: flex;
    flex-direction: column;
    align-items: center; /* Keeps items centered */

    margin: 0 auto; /* Center the container itself */
    width: 100%; /* Ensure it takes up the available width */
  }

  

  .infoBox {

    width: 100%;
    max-width: 575px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;

  }
  
  /* Update infoButton to fill its container but respect the overall max width */
  .infoButton {
    background-color: #E9DCD1;
    height: 35px;
    border: 0.1px solid rgb(76, 76, 76);
    border-radius: 2px;
    cursor: pointer; /* Indicate it's clickable */
    margin-bottom: 5px 0;
    margin-top: -40px;
    width: 100%; /* Fill the container width */
    justify-content: center;
    display: flex;
    align-items: center;
    transition: opacity 0.1s;
  }

  .bottomAction{
    display: flex;
    flex-direction: column; /* Stack buttons vertically */
    gap: 10px; /* Space between the buttons */
    margin-top: 20px; /* Space above the button section */
    width: 100%; /* Ensure the buttons span the full width of the container */
    margin-bottom: 20px;
  }


  .infosButtoneasy {
    background-color: #E9DCD1;
    height: 35px;
    border: 0.1px solid rgb(76, 76, 76);
    border-radius: 2px;
    cursor: pointer; /* Indicate it's clickable */
    margin-bottom: 0px;
    margin-top: 5px;
    width: 100%; /* Fill the container width */
    justify-content: center;
    display: flex;
    align-items: center;
    transition: opacity 0.1s;
  }

  .editProfilePage-divider {
    border-bottom: 1px solid #000; /* Make it a bit thicker and ensure the color is solid black */
    opacity: 0.1; /* Ensure it's fully opaque */
    width: 100%; /* Ensure it spans the full width */
    margin-top: 0px;
    margin-bottom: 20px;
    

}

  .headshotLink {
    display: flex;
    display: inline-flex; 
    align-items: center; /* Center items vertically */
    justify-content: center; /* Center items horizontally */
    cursor: pointer; /* Indicate it's clickable */
    padding: 10px; /* Optional: Adjust padding as needed */
    margin: 5px 0; /* Space it out from other elements */

  }

  .centerWrapper {
    display: flex;
    justify-content: center; /* Center horizontally */
    width: 100%; /* Take up full width of the parent to properly center the child */
  }

  .linkText {
    font-family: sans-serif;
    letter-spacing: 0.05em;
    color: black;
    font-size: 0.8em;
    /* Since this is not a block element, text-align won't have an effect here. */
    /* Additional styling as needed to match other text */
  }
  
  

  
  /* Adjusting text elements styling within infoBox */
  .infoBox .authorBio, .infoBox .sectionTitle, .infoBox .infoList, .infoBox .noInfoList  {
    font-family: sans-serif;
    letter-spacing: 0.05em;
    text-align: justify;
    color: black;
  }

  .bioContainer {
    width: 100%;

  }
  .authorBio {
    width: 100%;
    text-align: left; 
  }



  /* Specific styling adjustments for authorBio and sectionTitle if needed */
  .infoBox .authorBio {
    font-size: 0.9em;
  }

  .infoBox .sectionTitle {
    font-size: 1.1em;
    margin-bottom: 10px; /* Space between title and content */
  }
  
  .topCard {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .profileImage {
    width: 60px; /* Set the width as needed */
    height: 60px; /* Maintain aspect ratio */
    border-radius: 50%; /* Make the image round */
    margin-right: 20px;
  }
  
  .authorInfo {
    /* Additional styling for author information */
  }
  
  .authorName {
    font-size: 24px;
    /* Additional styling for the author's name */
  }
  
  .generationInfo {
    font-size: 18px;
    color: #555;
    /* Additional styling for the generation information */
  }
  