/* RecentBooksProfile.css */
.recent-books-profile {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 536px; /* Match the max-width in ProfilePage.css */
    margin: 0px auto;
    margin-top: 10px;
}

.recent-books-profile .feed-name {
    font-weight: bold;
    margin-bottom: 8px;
    font-size: 16px;
    text-align: left;
}

.recent-books-profile .feed-items {
    display: flex;
    overflow-x: auto;
    scrollbar-width: thin;
    gap: -10px;
    margin: 0px 0px;
    margin-bottom: 0px;
    padding: 0px 0;
}

.recent-books-profile .feed-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 93px;
    flex-shrink: 0;
    cursor: pointer;
}

.recent-books-profile .feed-item img {
    object-fit: cover;
    height: 120px;
    width: 80px;
}

.recent-books-profile .item-title {
    text-align: center;
    margin-top: 8px;
    font-size: 14px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.recent-books-profile .item-author {
    text-align: center;
    font-size: 12px;
    color: #666;
    margin-top: 4px;
}