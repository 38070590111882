
  
  .unique-modal-view-container {
    background: #FFF9F2;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 450px;
  }
  
  .unique-modal-view-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .unique-tab-title-container {
    flex: 1; /* Each container takes up equal space */
    display: flex;
    justify-content: center; /* Center content within each container */
  }
  
  .unique-tab-title {
    cursor: pointer;
    padding: 5px 10px;
    margin: 0;
    border-bottom: 2px solid transparent;
    font-size: 16px;
    color: grey;
    transition: color 0.3s;
    text-align: center;
  }
  
  .unique-tab-title-container.active .unique-tab-title {
    border-bottom: 2px solid #000;
    color: black;
  }
  
  .unique-modal-view-content {
    margin-bottom: 20px;
  }
  
  .unique-links-section, .unique-socials-section {
    display: flex;
    flex-direction: column;
  }
  
  .unique-modal-view-separator {
    border-bottom: 2px solid #d3d3d3;
    margin-bottom: 20px;
    width: 100%;

  }

  .modalest-foot {
    display: flex;
    justify-content: flex-end;
  }
  
  .unique-modal-close-button {
    background-color: #E9DCD1;
    height: 35px;
    border: 0.1px solid rgb(76, 76, 76);
    border-radius: 2px;
    cursor: pointer; /* Indicate it's clickable */
    margin-bottom: 5px 0;

    width: 100%; /* Fill the container width */
    justify-content: center;
    display: flex;
    align-items: center;
    transition: opacity 0.1s;
    box-sizing: border-box;
  }
  .unique-links-section, .unique-socials-section {
    max-height: 340px; /* Adjust based on your modal size */
    overflow-y: auto; /* Enables vertical scrolling if content overflows */
}



  