.details-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding-top: 120px;
    width: 100%;
  }
  
  .tag-details-boxes {
    margin: 0 auto; 
    border: 1px solid #d3d3d3; 
    width: 100%; /* Adjusted to the desired max width */
    max-width: 400px;
    box-sizing: border-box; 


    


    padding: 20px;

  }
  
  .titleContainerey {
    align-items: left; 
    width:100%;
    margin-top: 0px;
    margin-bottom: 20px;
  }
  
  .tag-details-title {
    font-size: 24px;
    margin-top: 0px;
    color: #2F2B27;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    margin-bottom: 10px;
    font-family: sans-serif;
    letter-spacing: 0.5px;

    
  }
  
  .image-previews {
    width: 100%;
    height: auto;
    margin-bottom: 9px;
  }
  
  .fileUploadContainer, .buttonRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between; /* Space out buttons */
    width: 100%; /* Fill container width */
    margin-bottom: 20px;
    gap: 10px; 
  }
  
  
  .fileInput {
    margin-bottom: 10px;
  }
  
  .infosButton-detailes {
    background-color: #E9DCD1;
    height: 35px;
    border: 0.1px solid rgb(76, 76, 76);
    border-radius: 2px;
    cursor: pointer; /* Indicate it's clickable */
    margin-bottom: 15px;
    margin-top: 25px;
  
    width: 100%; /* Fill the container width */
    justify-content: center;
    display: flex;
    align-items: center;
    transition: opacity 0.1s;
    box-sizing: border-box;
  }

  .infosButton-details-preview {
    background-color: #E9DCD1;
    height: 35px;
    border: 0.1px solid rgb(76, 76, 76);
    border-radius: 2px;
    cursor: pointer; /* Indicate it's clickable */
    margin-bottom: 20px;
    margin-top: 0px;
  
    width: 100%; /* Fill the container width */
    justify-content: center;
    display: flex;
    align-items: center;
    transition: opacity 0.1s;
    box-sizing: border-box;
  }

  .infosButton-details-previews {
    background-color: #E9DCD1;
    height: 35px;
    border: 0.1px solid rgb(76, 76, 76);
    border-radius: 2px;
    cursor: pointer; /* Indicate it's clickable */
    margin-bottom: 10px;
    margin-top: 20px;
  
    width: 100%; /* Fill the container width */
    justify-content: center;
    display: flex;
    align-items: center;
    transition: opacity 0.1s;
    box-sizing: border-box;
  }

  .infosButton-details.disabled {
    color: gray; 
    cursor: default;
  }
  
  .infosButton:hover {
    opacity: 0.9;
  }

  .details-dividers {
    border-bottom: 1px solid #000; /* Make it a bit thicker and ensure the color is solid black */
    opacity: 0.2; /* Ensure it's fully opaque */
    width: 100%; /* Ensure it spans the full width */
    margin-top: 15px;
    margin-bottom: 10px;
    align-items: center;

}


  