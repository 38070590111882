.about-words {
    max-width: 800px;
    margin: 20px auto;
    padding: 0 20px;
    box-sizing: border-box;
    width: 100%;

  }

  .home-readers-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 10px;
    /* other styling */
  }
  
  .hero-section {
    text-align: center;
    margin-top: 2rem;
    /* optionally, add a background color or gradient */
  }

  .heros-sections {
    text-align: center;
    margin-top: 1rem;

    /* optionally, add a background color or gradient */
  }
  .heros-headings {

    margin-top: 0px;
    /* optionally, add a background color or gradient */
  }

  .hero-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px; /* optional for style */
  }
  
  .about-words h1 {
    margin-bottom: 20px;
  }
  
  .about-words p {
    line-height: 1.6;
    margin-bottom: 20px;
  }

  .about-words {
    color: black;
  }
  
  .about-words h3 {
    color: black;
    margin-bottom: 20px;
  }
  .subtlest-line-aboutwords {
    width: 100%;
    height: 1px;      /* or 2px, depending on how thick you want it */
    background-color: #eee;
    margin-top: 40px;

  }

  .cta-button {
    background-color: #E9DCD1;
    height: 35px;
    border: 0.1px solid rgb(76, 76, 76);
    border-radius: 2px;
    cursor: pointer;
    margin-top: 15px;
    margin-bottom: 0px;
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    transition: opacity 0.1s;
    box-sizing: border-box;
    font-size: 16px;
    letter-spacing: 0.05em;
    color: black;
}
  
  .about-list {
    list-style-type: none;
    padding: 0;
  }
  
  .about-list li {
    margin-bottom: 20px;
    padding-left: 30px;
  }
  
  .about-list a {
    color: black;
    text-decoration: underline; 
  }
  
  .about-list a:hover {
    text-decoration: underline;
  }

  .image-birdies-container {
    display: flex;
    justify-content: center;
    margin: 10px 0;

  }
  
  .centered-birdie-image {
    max-width: 80%;
    height: auto;
  }

  .about-words .clickable-link {
    color: black;  /* Traditional link color, you can change this */
    text-decoration: underline;
    cursor: pointer;
  }

  