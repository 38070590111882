/* AppFormSocials.css */
.applyFormSocialsContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    background-color: #FFF9F2;
    border-radius: 5px;
    border: 1px solid #d3d3d3;
    max-width: 350px;
    margin: auto;
    font-family: Arial, sans-serif;
  }
  
  .bold-background {
    font-weight: bold;
    font-size: 18px;
    color: black;
    text-align: center;
  }
  
  .titleContainer-social {
    width: 100%;
    text-align: center;

  }

  .contentishList {
    width: 88%;
    margin: 10px 0; /* Ensures the container spans the full width of its parent */
    display: flex;
    flex-direction: column;
  }
  

  .dashed-separator-social {
    border-bottom: 1px dashed #d3d3d3; /* Change from solid to dashed */
    margin-top: 10px;
    margin-bottom: 20px;;
    width: 100%;
  }
  /* Add styles for your social media and published inputs/modals */

  .socialsButtons, .discardButton {
    background-color: #E9DCD1;
    color: black;
    border: none;
    padding: 10px 20px;
    border-radius: 2px;
    cursor: pointer;
    width: 100%;
    margin-bottom: 10px;
  }
  .applyButtonText {
    font-weight: bold;
  }


  .magazineButton {
    background-color: #E9DCD1;
    color: black;
    border: none;
    padding: 10px 20px;
    border-radius: 2px;
    cursor: pointer;
    width: 100%;
    margin-bottom: 10px;

  }
  
  
  .separator {
    border-bottom: 1px solid #d3d3d3;
    margin: 20px 0;
    width: 100%;
  }

  .description {
    line-height: 1.6;
    /* margin-bottom: 20px; */
    text-align: left; /* Left align text in paragraphs */
    color: black;
}
  
  /* Add any additional styles as needed */
  