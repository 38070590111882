.about-writers-words {
    max-width: 670px;
    margin: 20px auto;
    padding: 0 20px;
    box-sizing: border-box;
  }
  
  .about-writers-words h1 {
    margin-bottom: 20px;
  }
  
  .about-writers-words p {
    line-height: 1.6;
    margin-bottom: 20px;
  }

  
  .cta-button {
    background-color: #E9DCD1;
    height: 35px;
    border: 0.1px solid rgb(76, 76, 76);
    border-radius: 2px;
    cursor: pointer;
    margin-top: 15px;
    margin-bottom: 20px;
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    transition: opacity 0.1s;
    box-sizing: border-box;
    font-size: 16px;
    letter-spacing: 0.05em;
    color: black;
    
}


  .about-writers-words li {
    line-height: 1.6;
    margin-bottom: 5px;
  }

  .profile-section {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    gap: 50px;
    /* max-width: 400px; */
    width: 100%;

}

.inlinable-image {
    width: auto;  /* Adjust this value to your preferred size */
    height: 140px;
    vertical-align: right;
}
.uninlinable-image {
  width: 100%;  /* Adjust this value to your preferred size */
  height: auto;
  vertical-align: right;
  margin-bottom: 20px;
}