/* Equivalent to summaryAuthor */
.authorSummary-wrapper {
    width: 100%;
    max-width: 575px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
}


.authorSummary {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    padding-top: 5px; 
  }
  
  /* Equivalent to centeredText */
  .centeredText {
    text-align: center;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: -4px;
    color: grey;
  }
  
  /* Equivalent to topCard */
  .topCard-summary {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    /* margin-bottom: 10px; */
    padding:0px;
    width: 100%;
  }
  
  /* Equivalent to bioContainer */
  .bioContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .imageContainer-summary {
    width: 40%; /* Set the container width to 40% of its parent */
  /* Center the container if needed */
    margin: 0px;
    padding: 0px;
  }
  
  .authorSummary-image {
    width: 100%; /* Make the image take up 100% of the container's width */
    height: auto; 
    margin: 0px;
    padding: 0px;
  }
  

  
  /* Equivalent to authorInfo */
  .authorName-container {
    width: 100%;
    text-align: center; /* This centers the content horizontally within the container */
}
.authorSummary-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    /* No need to center align items here */
  }
  .info-label {
    font-weight: bold;
  }
  .authorSummary-name {
    margin-bottom: 15px;

  }

  .summary-info {
    margin-bottom: 2px;
    margin-top: 4px;
    margin-right: 15px;
    margin-left: 15px;
  }

  .summary-info-center {
    margin-bottom: 2px;
    margin-top: 4px;
    margin-right: 15px;
    margin-left: 15px;
    font-family: sans-serif;
    letter-spacing: 0.05em;
    font-size: 0.9em;
  }

  .summary-info, .authorSummary-bio p{
    font-family: sans-serif;
    letter-spacing: 0.05em;
    text-align: justify;
    font-size: 0.9em; /* Adjust size for bio if needed, separate class if different styling is desired */
    
  }
  
  /* Equivalent to infoList */
  .infoList {
    font-size: 16px;
    margin-bottom: 10px;
    letter-spacing: 1px;
    color: black;
    margin-right: 20px;
  }
  
  /* Equivalent to noInfoList */
  .noInfoList {
    font-size: 16px;
    margin-bottom: 10px;
    letter-spacing: 1px;
    color: black;
    text-align: center;
    margin-right: 20px;
  }
  
  /* Equivalent to authorBio */
  .authorSummary-bio {
    font-size: 17px;

    margin-bottom: -10px;
    color: black;
    /* Apply textInBox style equivalent here */
  }
  .authorSummary-name {
    text-align: center;
    width: 100%; 
    font-weight: bold;
    font-size: 1.0em; /* Adjust size as needed */
    font-family: sans-serif;
    letter-spacing: 0.08em;
    width: 100%;
  }
  
  /* Equivalent to lightText */
  .lightText {
    color: black;
  }
  
  .profilePage-divider {
    border-bottom: 1px solid #000; /* Make it a bit thicker and ensure the color is solid black */
    opacity: 0.1; /* Ensure it's fully opaque */
    width: 100%; /* Ensure it spans the full width */
    margin-top: 20px;

}


.profilishButtonInline {
  background-color: #E9DCD1; /* Primary button color */
  height: 35px;
  border-color: rgb(76, 76, 76);
  border-width: 0.1px;
  border-style: solid;
  border-radius: 2px;
  margin-top: 10px;
  margin-bottom: 0px;
  width: 100%;
  cursor: pointer;

  justify-content: center;
  display: flex;
  align-items: center;
}

.profilishButtonRow {
  display: flex;
  justify-content: space-between;

  width: 100%;
}

.profilishButtonText {
  color: black;
  text-align: center;
  width: 100%;
}