.gateway-box {
    border: 1px solid #d3d3d3;   /* more subtle, brown color for the border */
    border-radius: 2px; /* beveled corners */
    padding: 0px;
    padding-bottom: 0px;
    width: 275px;
    display: flex;
    margin-bottom: 0px;
    flex-direction: column;
    align-items: center;
  }
  

.gateway-content {
    width: max-content; /* Adjusted to fit the content */
    display: inline-block;
    flex-direction: column;
    align-items: center;
  }
  
  .gateway-text {
    margin-bottom: 20px;
    font-size: x-large; /* Make the text larger */
    font-family: 'Brush-Script-MT'; 
  }

  .gateway-button-login{
    background-color: #E9DCD1;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 20px;
    color: black;
    border: 0.1px solid; /* matching border for the button */
    border-radius: 4px; /* beveled corners for the button */
    padding: 7px;
    text-align: center; /* center the button text */
    font-weight: normal;
    letter-spacing: 0.08em;
    cursor: pointer;

  }
  