/* ForgotPasswordBox.css */
.forgotPasswordBox {
    border: 1px solid #d3d3d3;
    border-radius: 2px;
    padding: 20px;
    width: 350px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .input {
    height: 40px;
    width: 100%;
    border: 1px solid gray;
    background-color: white;
    margin-bottom: 10px;
  }
  
  .submitButton {
    background-color: #E9DCD1;
    height: 40px;
    border: 0.1px solid black;
    border-radius: 2px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .error {
    color: red;
    margin-top: 10px;
  }

  .returnLogin {
    color: black;
    text-align: center;
    background: none;
    border: none;
    padding: 5px;
    cursor: pointer;
  }
  
  .separator {
    border-bottom: 1px solid #d3d3d3;
    margin: 10px 0;
    width: 100%;
  }
  