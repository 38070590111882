.find__search-container {
    width: 100%;
    background-color: #FFF9F2;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

.find {
    margin-bottom: 50px;
}

.find__divider {
    width: 100%;
    height: 1px;
    background-color: #d3d3d3;
    margin-bottom: 10px;
}

.find__search-bar {
    width: 90%;
    max-width: 400px;
    padding: 10px;
    border: 1px solid #d3d3d3;
    border-radius: 6px;
    font-size: 16px;
    color: #333;
    box-sizing: border-box;
    margin-bottom: 10px; /* Add some space between input and button */
}

.find__search-bar::placeholder {
    color: #999;
}

.find__message {
    color: #666;
    font-size: 16px;
    text-align: center;
    margin: 10px 0;
  }
  
  .find__submit-button--disabled {
    opacity: 0.6;
    pointer-events: none;
  }
  

.find__submit-button {
    background-color: #E9DCD1; /* Inverted background color */
    color: black;
    padding: 10px 20px;
    border: 0.5px solid #666666; 
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    max-width: 400px; /* Aligns with the input width */
    width: 100%;
    box-sizing: border-box;
    margin:0px;
    transition: background-color 0.3s ease;
}

.find__submit-button:hover {
    background-color: #E9DCD1; /* Slight color change on hover */
}

.find__results-list {

    max-width: 400px; /* Matches the max-width of the input and button */
    margin: 0 auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

}
