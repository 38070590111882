.about-readers-words {
    max-width: 670px;
    margin: 20px auto;
    padding: 0 20px;
    box-sizing: border-box;
  }
  
  .about-readers-words h1 {
    margin-bottom: 20px;
  }
  
  .about-readers-words p {
    line-height: 1.6;
    margin-bottom: 20px;
  }



  .about-readers-words li {
    line-height: 1.6;
    margin-bottom: 5px;
  }
