.pieces-words {
    max-width: 800px;
    width: 100%;
    /* margin: 20px auto;
    padding: 0 20px; */
    box-sizing: border-box;
  }
  
  
  .pieces-words .authors-name {
    margin-left: 10px;
    font-weight: normal;
  }

  .pieces-content {
    line-height: 1.5; /* Adds space between lines */
  }