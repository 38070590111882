/* HorizontalFeedBooks.css */

.dash-horizontal-feed-pieces {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 430px;
    margin: 0px 20px;
  }
  

  .dash-horizontal-feed-pieces .feed-name {
    font-weight: bold;
    margin-bottom: 8px;
    font-size: 16px;
  }
  
  .dash-horizontal-feed-pieces .feed-items {
    display: flex;
    overflow-x: scroll;
    scrollbar-width: thin;
    gap: 16px;
    margin-bottom: 0px;
  }
  
  .dash-horizontal-feed-pieces .feed-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
  }
  
  .dash-horizontal-feed-pieces .feed-item img {
    object-fit: cover;
    height: 180px;
    width: 180px;
  }
  
  .dash-horizontal-feed-pieces .item-title {
    text-align: center;
    margin-top: 0px;
    font-size: 14px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }