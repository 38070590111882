.set-style-genre-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 1000;
  }
  
  .style-genre-modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    min-width: 300px;
  }
  
  .style-genre-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .style-genre-close-button,
  .style-genre-confirm-button {
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    background-color: #E9DCD1;
  }
  
  .style-genre-selector-container {
    display: flex;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    margin-bottom: 20px;
    
  }
  
  .style-genre-selector {
    flex: 1;
    padding: 10px;
    cursor: pointer;
    border: none;
    background: none;
  }
  
  .style-genre-selected {
    font-weight: bold;
  }
  
  .style-genre-options-container {
    max-height: 300px;
    overflow-y: auto;
  }
  
  .style-genre-option-item {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #eee;
  }
  
  
  .style-genre-selected-top {
    background-color: #E9DCD1;
    font-weight: bold;
  }
  