.gateway-box-login {
    border: 1px solid #d3d3d3;   /* more subtle, brown color for the border */
    border-radius: 2px; /* beveled corners */
    padding: 0px;
    padding-bottom: 30px;
    width: 275px;
    display: flex;
    margin-bottom: 30px;
    flex-direction: column;
    align-items: center;
  }

.gateway-content-login {
    width: 100%; /* Changed from max-content to 100% */
    display: flex; /* Changed from inline-block to flex */
    flex-direction: column;
    align-items: center;
  }

  .gateway-text-login {
    margin-bottom: 20px;

    font-size: x-large; /* Make the text larger */
    font-family: 'Brush-Script-MT'; 
    text-align: center;
    width: 100%;

  }


  .gateway-login-button-login{
    background-color: #E9DCD1;
    width: 100%;
    box-sizing: border-box;

    margin-bottom: 0px;
    color: black;
    border: 0.1px solid; /* matching border for the button */
    border-radius: 4px; /* beveled corners for the button */
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 20px;
    padding-right: 20px;

    text-align: center; /* center the button text */
    font-weight: normal;
    letter-spacing: 0.08em;
    cursor: pointer;

  }
  