.header-main {
    font-family: Prestige-Normal;
    font-size: 24px;
    text-align: center;
    background-color: #E9DCD1;
    color: #30221C;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    margin: 0; 
    /* padding: 20px 0; */
    letter-spacing: 4px; 
    position: relative;
  }
  
  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 575px;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
  }
  
  .header-content h1 {
    margin: 0;
    padding: 0;
    font-size: inherit;
    font-weight: bold;
    font-family: Prestige-Normal;
  }
  
  .pancake-button {
    position: relative;
  }
  
  .pancake-button button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
  }
  
  .pancake-button img {
    width: 35px;
    height: 35px;
  }

  .filter-indicator {
    position: absolute;
    top: -10px;  /* Adjust this value to move the F up or down */
    right: -10px;  /* Adjust this value to move the F left or right */

    color: black;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    /* font-weight: bold; */
    z-index: 1;  /* Ensure the F appears above the pancake image */
  }