body, html {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: 'Prestige-Normal';
  src: url('./assets/fonts/Prestige-Normal.woff2') format('woff2'),
       url('./assets/fonts/Prestige-Normal.woff') format('woff'),
       url('./assets/fonts/Prestige-Normal.ttf') format('truetype'); /* Add the TrueType format */
}

@font-face {
  font-family: 'Brush-Script-MT';
  src: url('./assets/fonts/BrushScriptMT.woff2') format('woff2'), /* WOFF2 for modern browsers */
       url('./assets/fonts/BrushScriptMT.woff') format('woff'),   /* WOFF for other modern browsers */
       url('./assets/fonts/BrushScriptMT.ttf') format('truetype'); 
}
