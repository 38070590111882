.editor-title {
    background-color: #E9DCD1;
    font-family: 'Prestige-Normal', sans-serif; /* Ensure you have this font available */
    text-align: center;
    width: 100%;
    padding-top: 8px;
    border-bottom: 1px solid #d3d3d3; 
    box-sizing: border-box; /* Makes sure padding does not increase the element's total width */
    font-size: 18px; /* Adjust the size as needed */
    margin-bottom: 0px; /* Creates some space between the title and the toolbar */
  }

  .editable {
    background-color: white; /* Set background to white */
    padding: 0px; /* Adjust padding as needed */
    padding-left: 10px; /* Adjust padding as needed */
    padding-right: 10px; /* Adjust padding as needed */
    border: none; /* Remove border if any */
    height: 150px;
    overflow-y: auto;
    font-family: sans-serif; /* Changes the font to sans-serif */
    letter-spacing: 0.05em; 
    margin-top: 10px;
  }

  .editable p {
    margin-top: 0px; /* Eliminates gap at the start */
    margin-bottom: 17px; /* Adjust the gap between paragraphs */
  }

  .block-quote {
    margin: 0.5em 10px; /* Margin around the quote */
    padding: 0.5em 10px; /* Padding inside the quote */
    font-style: italic; /* Italic font style */
    color: gray;
    /* Any other styling you want to apply */
  }
  


  .editable:focus {
    outline: none; /* Removes the default focus outline */
    /* Or apply custom styling as needed */
  }