.pendingWriterComponent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    background-color: #FFF9F2;
    border-radius: 5px;
    border: 1px solid #d3d3d3;
    max-width: 350px;
    margin: auto;
    font-family: Arial, sans-serif;
    
  }
  
  .messageBox {
    margin-bottom: 20px;
    text-align: left;
    
    
  }
  .bold {
    font-weight: bold;
    font-size: 18px;
    text-align: center;
}
  
  .checkStatusButton, .signOutButton {
    background-color: #E9DCD1;
    color: black;
    border: none;
    padding: 10px 20px;
    border-radius: 2px;
    cursor: pointer;
    width: 100%;
    margin-bottom: 10px;
  }
  .separator {
    border-bottom: 1px solid #d3d3d3;
    margin: 20px 0;
}
  
  /* Adjust the margin between buttons if needed */
  .checkStatusButton + .signOutButton {
    margin-top: 10px;
  }
  