.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: #FFF9F2;
    padding: 20px;
    border-radius: 10px;
    max-width: 450px;
    width: 90%;
    z-index: 1001; 
  }
  
  .test-button {
    position: absolute;
    bottom: 20px;
    left: 20px;
    padding: 10px 20px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
