.reusableBoxContainered {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .contentViewsomuchInBox {
    width: 100%;
    padding: 10px;
    border: 1px solid #d3d3d3;
    margin-bottom: 20px;
    position: relative; /* Ensure the container is positioned relatively */
    text-align: center;
  }
  
  .textInBoxcar {
    font-size: 16px;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 0px;
    font-family: sans-serif;
    margin-left: 10px;
    margin-right: 10px;
  }
  
  .boldsterInBox {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 0px;
    font-family: sans-serif;
  }
  
  .normailerInBox {
    font-weight: normal;
    font-size: 18px;
    margin-bottom: 10px;
    margin-top: 10px;
    font-family: sans-serif;
    font-style: italic;
  }
  
  /* Adjusted size and positioning for the close icon */
  .removeIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px; /* Smaller width */
    height: 20px; /* Smaller height */
    cursor: pointer;
  }
  