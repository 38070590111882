.authorSummarySearch-wrapper {
    width: 100%;
    max-width: 575px;
    margin: 0 auto;
    margin-bottom: 10px;

    box-sizing: border-box;
}

.authorSummarySearch-topCard {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    width: 100%;
    cursor: pointer;
}

.authorSummarySearch-imageContainer {
    width: 40%;
    margin: 0;
    padding: 0;
}

.authorSummarySearch-topDivider {
    border-top: 1px solid #d3d3d3; /* Light gray divider */
    width: 100%; /* Full width */
    margin-bottom: 10px; /* Space below the divider */
    opacity: 0.6; /* Slightly transparent */
}

.authorSummarySearch-image {
    width: 100%;
    height: auto;
}

.authorSummarySearch-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 15px;
}

.authorSummarySearch-nameContainer {
    width: 100%;
    text-align: center;
}

.authorSummarySearch-name {
    font-weight: bold;
    font-size: 1em;
    font-family: sans-serif;
    letter-spacing: 0.08em;
    margin-bottom: 10px;
}

.authorSummarySearch-infoDetail {
    margin: 4px 15px;
    font-family: sans-serif;
    letter-spacing: 0.05em;
    font-size: 0.9em;
}

.authorSummarySearch-label {
    font-weight: bold;
}

.authorSummarySearch-buttonRow {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 0px;
}

.authorSummarySearch-button {
    background-color: #E9DCD1;
    height: 35px;
    border: 0.5px solid #666666; 
    border-radius: 2px;
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin-bottom: 0px;
    align-items: center;
}

.authorSummarySearch-buttonText {
    color: black;
    text-align: center;
    width: 100%;
    margin-bottom: 0px;
}
